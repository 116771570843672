import * as math from "mathjs";
import { getNodes } from "../getNodes";
import { autoSetRangeForMultipleCards } from "../CardStatAutoSettingAlgorithms/autoSetRangeForMultipleCards";

math.config({
  number: "BigNumber",
  precision: 64
});

export function ratioOfConstants(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope,
  specialSymbols
) {
  const nodes = getNodes(expr);

  if (
    nodes.accessorNodes.length === 0 &&
    nodes.arrayNodes.length === 0 &&
    nodes.assignmentNodes.length === 0 &&
    nodes.blockNodes.length === 0 &&
    nodes.conditionalNodes.length === 0 &&
    nodes.symbolNodes.length === 0 &&
    (nodes.constantNodes.length >= 1 || nodes.symbolNodes.length >= 1) &&
    nodes.symbolNodes.every(node => {
      return specialSymbols.realConstants.includes(node.name);
    }) &&
    nodes.functionalAssignmentNodes.length === 0 &&
    nodes.indexNodes.length === 0 &&
    nodes.objectNodes.length === 0 &&
    nodes.operatorNodes.length >= 0 &&
    nodes.operatorNodes.every(node => {
      return node.fn === "unaryMinus" || node.fn === "unaryPlus";
    }) &&
    nodes.parenthesisNodes.length >= 0 &&
    nodes.rangeNodes.length === 1 &&
    nodes.relationalNodes.length === 0
  ) {
    try {
      math.eval(expr);
    } catch (error) {
      return false;
    }

    let leftConstant = nodes.rangeNodes[0].start;
    let rightConstant = nodes.rangeNodes[0].end;
    let leftConstantValue = math.eval(leftConstant.toString());
    let rightConstantValue = math.eval(rightConstant.toString());

    let cardScope = {
      dsfryugh: leftConstantValue,
      wupuytv: rightConstantValue
    };

    let cardsMin =
      autoSetRangeForMultipleCards([leftConstantValue, rightConstantValue])
        .min * 2;
    let cardsMax =
      autoSetRangeForMultipleCards([leftConstantValue, rightConstantValue])
        .max * 2;
    if (cardContainers[inputContainerIndex] && math.eval(expr)) {
      // alter existing cards
      return {
        content: {
          color: inputContainerColor,
          cards: [
            {
              play: false,
              cardDisabled: false,
              valueEditable: true,
              value: leftConstantValue ? leftConstantValue.toString() : "",
              min: cardsMin,
              max: cardsMax,
              minText: cardsMin.toString(),
              maxText: cardsMax.toString(),
              independentExpression: "dsfryugh",
              dependentExpression: `dsfryugh = ${leftConstantValue}/${rightConstantValue}*wupuytv`,
              expression: `dsfryugh = ${leftConstantValue}/${rightConstantValue}*wupuytv`,
              expressionText: leftConstantValue.toString(),
              step: math.abs(leftConstantValue),
              stepText: math.abs(leftConstantValue).toString(),
              unit: cardContainers[inputContainerIndex].content.cards[0]
                ? cardContainers[inputContainerIndex].content.cards[0].unit
                : "obj"
            },
            {
              play: false,
              cardDisabled: false,
              valueEditable: true,
              value: rightConstantValue ? rightConstantValue.toString() : "",
              min: cardsMin,
              max: cardsMax,
              minText: cardsMin.toString(),
              maxText: cardsMax.toString(),
              independentExpression: "wupuytv",
              dependentExpression: `wupuytv = ${rightConstantValue}/${leftConstantValue}*dsfryugh`,
              expression: `wupuytv = ${rightConstantValue}/${leftConstantValue}*dsfryugh`,
              expressionText: rightConstantValue.toString(),
              step: math.abs(rightConstantValue),
              stepText: math.abs(rightConstantValue).toString(),
              unit: cardContainers[inputContainerIndex].content.cards[1]
                ? cardContainers[inputContainerIndex].content.cards[1].unit
                : "obj"
            }
          ]
        },
        scope: cardScope
      };
    } else return false;
  }
}
