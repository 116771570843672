import React, { useEffect } from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import manifestFlame from "../images/ManifestFlame.png";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "white",
    borderBottomColor: "lightgrey",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px"
  },
  menuButton: {
    marginRight: 0
  },
  hide: {
    display: "none"
  },
  saveButton: {
    color: "black",
    fontFamily: "Montserrat, sans-serif"
  },
  simulationNameRoot: {
    color: "black",
    fontFamily: "Montserrat, sans-serif",
    paddingRight: "15px"
  },
  simulationNameInput: {
    textAlign: "right"
  }
}));

const TopBar = props => {
  const classes = useStyles();

  let open = props.open;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleClickOpen() {
    setDialogOpen(true);
  }

  function handleClose() {
    setDialogOpen(false);
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className={classes.appBar}
      color="inherit"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={props.handleDrawerOpen}
          edge="start"
          className={clsx(open && classes.hide)}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={props.handleDrawerClose}
          edge="start"
          className={clsx(!open && classes.hide)}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
        <span style={{ flexGrow: 1 }} />
        <img width={20} src={manifestFlame} alt="" />
        <span style={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => props.toggleAdminDrawer(true)}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
