import valueToVerticalPixel from "../valueToVerticalPixel";
import * as math from "mathjs";

const drawIntervalNumbers = (
  ctx,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step
) => {
  for (let i = 0; i < 20; i++) {
    //get text width
    var textWidth = ctx.measureText(
      math.eval(`${firstLinePosition} + ${step} * ${i}`).toString()
    );

    //create white background rectangles for text so they aren't on top of the lines.
    ctx.fillStyle = "white";

    // draw left hand side rectangles
    ctx.fillRect(
      0,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      ) - 20,
      textWidth.width + 22,
      40
    );

    // draw right hand side rectangles
    ctx.fillRect(
      canvasWidth - textWidth.width - 22,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      ) - 20,
      textWidth.width + 22,
      40
    );

    //format text
    ctx.font = "24px Montserrat";
    ctx.textBaseline = "middle";

    //format text for left hand side
    ctx.textAlign = "start";
    ctx.fillStyle = "grey";

    //draw left hand side text
    ctx.fillText(
      math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
      10,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      )
    );

    // format text for right hand side
    ctx.textAlign = "end";
    ctx.fillStyle = "grey";

    //draw left hand side text
    ctx.fillText(
      math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
      canvasWidth - 10,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      )
    );
  }
};

export default drawIntervalNumbers;
