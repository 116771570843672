import React from "react";
import CardPlayButton from "./cardPlayButton";
import CardSliderRange from "./cardSliderRange";
import CardValue from "./cardValue";
import CardUnits from "./cardUnits";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  storyCardBottom: {
    height: 40
  },
  rightBorder: {
    borderRightColor: props => props.color,
    borderRightStyle: "solid",
    borderRightWidth: 1
  }
});

const CardBottom = props => {
  const classes = useStyles(props);
  return (
    <Grid
      container
      alignContent="flex-end"
      justify="center"
      alignItems="center"
      className={classes.storyCardBottom}
    >
      <Grid item className={classes.rightBorder} xs={1}>
        <CardPlayButton
          color={props.color}
          togglePlay={props.togglePlay}
          containerIndex={props.containerIndex}
          cardIndex={props.cardIndex}
          card={props.card}
          animateValue={props.animateValue}
        />
      </Grid>
      <Grid item className={classes.rightBorder} xs={7}>
        <CardSliderRange
          containerIndex={props.containerIndex}
          cardIndex={props.cardIndex}
          updateCardMinText={props.updateCardMinText}
          updateCardMaxText={props.updateCardMaxText}
          updateCardMax={props.updateCardMax}
          updateCardMin={props.updateCardMin}
          updateCardStepText={props.updateCardStepText}
          updateCardStep={props.updateCardStep}
          card={props.card}
          color={props.color}
        />
      </Grid>
      <Grid item className={classes.rightBorder} xs={3}>
        <CardValue
          containerIndex={props.containerIndex}
          cardIndex={props.cardIndex}
          changeValueInput={props.changeValueInput}
          updateCardValueText={props.updateCardValueText}
          card={props.card}
          color={props.color}
        />
      </Grid>
      <Grid item xs={1}>
        <CardUnits
          changeUnits={props.changeUnits}
          containerIndex={props.containerIndex}
          cardIndex={props.cardIndex}
          card={props.card}
          color={props.color}
          style={{ marginLeft: 10 }}
        />
      </Grid>
    </Grid>
  );
};

export default CardBottom;
