import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Joi from "joi-browser";

const useStyles = makeStyles({
  dialogTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "30px"
  },
  inputStyles: {
    fontFamily: "Montserrat, sans-serif",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: "4px",
    width: "400px",
    marginLeft: "30px",
    marginRight: "30px",
    marginBottom: "12px",
    paddingLeft: "8px"
  },
  inputStylesFocused: {
    borderColor: props => props.manifestColors.blue,
    borderWidth: 2
  },
  dialogPaperStyles: {
    borderStyle: "solid",
    borderWidth: 6,
    borderColor: props => props.manifestColors.slate
  },
  signInButton: {
    fontFamily: "Montserrat, sans-serif",
    marginLeft: "12px",
    minWidth: "100px",
    color: props => props.manifestColors.red,
    borderColor: props => props.manifestColors.red,
    backgroundColor: props => props.manifestColors.red,
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: props => props.manifestColors.raspberry,
      borderColor: props => props.manifestColors.raspberry,
      color: "white"
    }
  },
  cancelButton: {
    fontFamily: "Montserrat, sans-serif",
    color: props => props.manifestColors.slate,
    borderColor: props => props.manifestColors.slate,
    backgroundColor: props => "white",
    color: props => props.manifestColors.slate,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "white"
    }
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center"
  },
  inputLabel: {
    marginLeft: "30px",
    marginRight: "30px",
    fontFamily: "Montserrat, sans-serif",
    marginBottom: "4px",
    marginTop: "0px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "30px",
    marginBottom: "30px"
  }
});

const DialogSignIn = ({
  dialogSignInOpen,
  handleSignInClose,
  handleSignInSubmit,
  manifestColors
}) => {
  const classes = useStyles({
    manifestColors
  });

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const emailSchema = {
    Email: Joi.string()
      .email()
      .required()
  };

  const passwordSchema = {
    Password: Joi.string().required()
  };

  const handleChangeEmail = event => {
    setUserEmail(event.currentTarget.value);
  };

  const handleChangePassword = event => {
    setUserPassword(event.currentTarget.value);
  };

  const validateOnSubmit = () => {
    let emailValidationResult = Joi.validate({ Email: userEmail }, emailSchema);
    if (emailValidationResult.error) {
      setEmailError(emailValidationResult.error.details[0].message);
    } else setEmailError("");

    let passwordValidationResult = Joi.validate(
      { Password: userPassword },
      passwordSchema
    );
    if (passwordValidationResult.error) {
      setPasswordError(passwordValidationResult.error.details[0].message);
    } else setPasswordError("");

    /*
    let tempErrors = {};
    if (userEmail.trim() === "") {
      tempErrors.emailError = "An email is required.";
      setEmailError("An email is required.");
    } else setEmailError("");

    if (userPassword.trim() === "") {
      tempErrors.passwordError = "A password is required.";
      setPasswordError("A password is required.");
    } else setPasswordError("");

     */
    return emailValidationResult.error || passwordValidationResult.error;
  };

  const handleSubmit = event => {
    event.preventDefault();

    let formErrors = validateOnSubmit();

    if (formErrors) return;

    handleSignInSubmit();

    // call the server
    console.log("Submitted");
  };

  const handleSignInCancel = () => {
    setUserEmail("");
    setUserPassword("");
    setEmailError("");
    setPasswordError("");
    handleSignInClose();
  };

  const emailErrorMessage =
    emailError.length > 0 ? (
      <div
        style={{
          marginLeft: "30px",
          marginTop: "-10px",
          marginBottom: "10px",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "12px",
          color: manifestColors.blue
        }}
      >
        {emailError}
      </div>
    ) : (
      <React.Fragment />
    );

  const passwordErrorMessage =
    passwordError.length > 0 ? (
      <div
        style={{
          marginLeft: "30px",
          marginTop: "-10px",
          marginBottom: "10px",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "12px",
          color: manifestColors.blue
        }}
      >
        {passwordError}
      </div>
    ) : (
      <React.Fragment />
    );

  return (
    <Dialog
      PaperProps={{
        elevation: 1,
        classes: { root: classes.dialogPaperStyles }
      }}
      open={dialogSignInOpen}
      onClose={handleSignInClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        id="form-dialog-title"
      >
        SIGN IN
      </DialogTitle>
      <DialogContent>
        <form>
          <p className={classes.inputLabel}>Email</p>
          <div className={classes.inputContainer}>
            <InputBase
              value={userEmail}
              onChange={event => handleChangeEmail(event)}
              classes={{ focused: classes.inputStylesFocused }}
              className={classes.inputStyles}
            />
          </div>
          {emailErrorMessage}

          <p className={classes.inputLabel}>Password</p>
          <div className={classes.inputContainer}>
            <InputBase
              onChange={event => handleChangePassword(event)}
              classes={{ focused: classes.inputStylesFocused }}
              className={classes.inputStyles}
            />
          </div>
          {passwordErrorMessage}

          <div className={classes.buttonContainer}>
            <Button
              className={classes.cancelButton}
              onClick={handleSignInCancel}
            >
              CANCEL
            </Button>
            <Button
              className={classes.signInButton}
              onClick={event => handleSubmit(event)}
            >
              SIGN IN
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSignIn;
