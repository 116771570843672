import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}));

const InputsDrawerHeader = ({ handleDrawerClose, appendInputContainer }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <React.Fragment>
      <div className={classes.drawerHeader}>
        <IconButton onClick={appendInputContainer}>
          <AddIcon />
        </IconButton>
        <span style={{ flexGrow: 1 }} />
        <IconButton onClick={handleDrawerClose}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </div>
      <Divider />{" "}
    </React.Fragment>
  );
};

export default InputsDrawerHeader;
