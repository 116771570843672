import * as math from "mathjs";

math.config({
  number: "BigNumber",
  precision: 64
});

const determineFirstLinePosition = (
  step,
  cardMin,
  minMaxRange,
  percentageOfCardMinMaxRangeTakesUp
) => {
  try {
    cardMin = math.bignumber(cardMin);
    step = math.bignumber(step);
    minMaxRange = math.bignumber(minMaxRange);
    percentageOfCardMinMaxRangeTakesUp = math.bignumber(
      percentageOfCardMinMaxRangeTakesUp
    );
    let bufferPercentage = math.divide(
      math.round(
        math.multiply(
          math.divide(
            math.divide(
              math.subtract(100, percentageOfCardMinMaxRangeTakesUp),
              2
            ),
            percentageOfCardMinMaxRangeTakesUp
          ),
          1000
        )
      ),
      1000
    );

    /*
  let bufferPercentage =
    math.round(
      ((100 - percentageOfCardMinMaxRangeTakesUp) /
        2 /
        percentageOfCardMinMaxRangeTakesUp) *
        1000
    ) / 1000;
    */
    let cardLowestValue = math.subtract(
      cardMin,
      math.multiply(bufferPercentage, minMaxRange)
    );

    cardLowestValue = math.bignumber(cardLowestValue);
    /*
  console.log(
    "Min: ",
    cardMin,
    "Other: ",
    math.multiply(bufferPercentage, minMaxRange),
    "Lowest: ",
    cardLowestValue,
    "step: ",
    step
  );
  */
    /*
  const cardLowestValue = Number(
    math.eval(`${cardMin} - (${bufferPercentage} * ${minMaxRange})`).toString()
  );
  */
    /*
  const firstLinePosition = math.multiply(
    math.floor(math.divide(cardLowestValue, step)),
    step
  );
  */

    const firstLinePosition = math.multiply(
      math.floor(math.divide(cardLowestValue, step)),
      step
    );
    /*
  const firstLinePosition = Number(
    math.eval(`floor(${cardLowestValue} / ${step}) * ${step}`).toString()
  );
  */

    return firstLinePosition.toNumber();
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export default determineFirstLinePosition;
