import drawVertical1DPoint from "./Vertical1DPoint/drawVertical1DPoint";
import drawTemperature from "./Temperature/drawTemperature";
import drawLuminousIntensity from "./LuminousIntensity/drawLuminousIntensity";
import drawLengthVelocity from "./LengthVelocity/drawLengthVelocity";
import drawLengthAcceleration from "./LengthAcceleration/drawLengthAcceleration";
import drawTime from "./Time/drawTime";
import drawMass from "./Mass/drawMass";
import drawMultiDirectional1DPoint from "./MultiDirectional1DPoint/drawMultiDirectional1DPoint";
import drawNumberOfObjects from "./NumberOfObjects/drawNumberOfObjects";

const drawCard = (
  ctx,
  cardValue,
  cardMin,
  cardMax,
  cardUnit,
  canvasWidth,
  canvasHeight,
  color
) => {
  //create white background rectangles for text so they aren't on top of the lines.
  ctx.fillStyle = "white";

  // draw left hand side rectangles
  ctx.fillRect(0, 0, canvasWidth, 40);
  if (cardUnit === "temp") {
    drawTemperature(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      canvasWidth,
      canvasHeight
    );
  } else if (cardUnit === "length velocity") {
    drawLengthVelocity(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      canvasWidth,
      canvasHeight
    );
  } else if (cardUnit === "length acceleration") {
    drawLengthAcceleration(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      canvasWidth,
      canvasHeight
    );
  } else if (cardUnit === "lum") {
    drawLuminousIntensity(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      canvasWidth,
      canvasHeight
    );
  } else if (cardUnit === "time") {
    drawTime(ctx, cardValue, cardMin, cardMax, canvasWidth, canvasHeight);
  } else if (cardUnit === "mass") {
    drawMass(ctx, cardValue, cardMin, cardMax, canvasWidth, canvasHeight);
  } else if (cardUnit === "length") {
    drawMultiDirectional1DPoint(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      canvasWidth,
      canvasHeight
    );
  } else if (cardUnit === "obj") {
    drawNumberOfObjects(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      canvasWidth,
      canvasHeight,
      color
    );
  } else {
    drawVertical1DPoint(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      canvasWidth,
      canvasHeight
    );
  }
};

export default drawCard;
