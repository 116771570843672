import { databaseManifestColors } from "./databaseManifestColors";

export function databaseExampleSimulations() {
  return [
    {
      simulationID: 1000,
      name: "Govt Fees",
      lastSaveDate: "January 7, 2019",
      open: true,
      inputContainers: [
        { status: "valid", input: "x=5", rawInput: "x=5", message: "" }
      ],
      scope: { x: 5 },
      cardContainers: [
        {
          content: {
            color: databaseManifestColors.red,
            cards: [
              {
                play: false,
                cardDisabled: false,
                valueEditable: false,
                value: 5,
                min: 0,
                max: 10,
                valueText: "5",
                minText: "0",
                maxText: "10",
                expression: "x",
                step: 0.1,
                stepText: "0.1",
                unit: "s"
              }
            ]
          },
          scope: { x: 5 }
        }
      ]
    },
    {
      simulationID: 1001,
      name: "Atomic Weight",
      lastSaveDate: "June 19, 2019",
      open: false,
      inputContainers: [
        { status: "valid", input: "a=7", rawInput: "a=7", message: "" }
      ],
      scope: { a: 7 },
      cardContainers: [
        {
          content: {
            color: databaseManifestColors.red,
            cards: [
              {
                play: false,
                cardDisabled: false,
                valueEditable: false,
                value: 7,
                min: 0,
                max: 12,
                valueText: "7",
                minText: "0",
                maxText: "12",
                expression: "a",
                step: 0.1,
                stepText: "0.1",
                unit: "multi"
              }
            ]
          },
          scope: { a: 7 }
        }
      ]
    },
    {
      simulationID: 1002,
      name: "Zebras Over Time",
      lastSaveDate: "June 21, 2019",
      open: true,
      inputContainers: [
        {
          status: "valid",
          input: "700(0.8)^t",
          rawInput: "700(0.8)^t",
          message: ""
        }
      ],
      scope: { t: 0 },
      cardContainers: [
        {
          content: {
            color: databaseManifestColors.red,
            cards: [
              {
                play: false,
                cardDisabled: false,
                valueEditable: true,
                value: 0,
                min: 0,
                max: 12,
                valueText: "0",
                minText: "0",
                maxText: "12",
                expression: "t",
                step: 0.1,
                stepText: "0.1",
                unit: "s"
              },
              {
                play: false,
                cardDisabled: false,
                valueEditable: false,
                value: 700,
                min: 0,
                max: 700,
                valueText: "700",
                minText: "0",
                maxText: "700",
                expression: "700(0.8)^t",
                step: 0.1,
                stepText: "0.1",
                unit: "obj"
              }
            ]
          },
          scope: { t: 0 }
        }
      ]
    }
  ];
}
