import * as math from "mathjs";
import { getNodes } from "../getNodes";
import { autoSetRangeForMultipleCards } from "../CardStatAutoSettingAlgorithms/autoSetRangeForMultipleCards";

math.config({
  number: "BigNumber",
  precision: 64
});

export function numericalExpressionMultiplication(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope,
  specialSymbols
) {
  const nodes = getNodes(expr);

  let binaryOperatorNodes = [];

  if (nodes.operatorNodes.length > 0) {
    binaryOperatorNodes = nodes.operatorNodes.filter(
      node => node.fn === "multiply"
    );
  }

  if (
    nodes.accessorNodes.length === 0 &&
    nodes.arrayNodes.length === 0 &&
    nodes.assignmentNodes.length === 0 &&
    nodes.blockNodes.length === 0 &&
    nodes.conditionalNodes.length === 0 &&
    nodes.symbolNodes.length === nodes.functionNodes.length &&
    (nodes.constantNodes.length >= 1 || nodes.symbolNodes.length >= 1) &&
    nodes.symbolNodes.every(node => {
      return specialSymbols.realConstants.includes(node.name);
    }) &&
    nodes.functionalAssignmentNodes.length === 0 &&
    nodes.indexNodes.length === 0 &&
    nodes.objectNodes.length === 0 &&
    nodes.operatorNodes.length >= 0 &&
    binaryOperatorNodes.length === 1 &&
    nodes.operatorNodes.every(node => {
      return (
        node.fn === "multiply" ||
        node.fn === "unaryMinus" ||
        node.fn === "unaryPlus"
      );
    }) &&
    nodes.parenthesisNodes.length >= 0 &&
    nodes.rangeNodes.length === 0 &&
    nodes.relationalNodes.length === 0
  ) {
    try {
      math.eval(expr);
    } catch (error) {
      return false;
    }
    let leftConstant = nodes.operatorNodes[0].args[0];
    let rightConstant = nodes.operatorNodes[0].args[1];
    let leftConstantValue = math.eval(leftConstant.toString());
    let rightConstantValue = math.eval(rightConstant.toString());
    let expressionValue = math.eval(expr);
    let cardsMin = autoSetRangeForMultipleCards([
      leftConstantValue,
      rightConstantValue,
      expressionValue
    ]).min;
    let cardsMax = autoSetRangeForMultipleCards([
      leftConstantValue,
      rightConstantValue,
      expressionValue
    ]).max;
    if (cardContainers[inputContainerIndex] && math.eval(expr)) {
      // alter existing cards
      return {
        content: {
          color: inputContainerColor,
          cards: [
            {
              play: false,
              cardDisabled: false,
              valueEditable: false,
              value: leftConstantValue ? leftConstantValue.toString() : "",
              min: cardsMin,
              max: cardsMax,
              minText: cardsMin.toString(),
              maxText: cardsMax.toString(),
              expression: leftConstant.toString(),
              step: 0.1,
              stepText: "0.1",
              unit: cardContainers[inputContainerIndex].content.cards[1]
                ? cardContainers[inputContainerIndex].content.cards[1].unit
                : "obj"
            },
            {
              play: false,
              cardDisabled: false,
              valueEditable: false,
              value: rightConstantValue ? rightConstantValue.toString() : "",
              min: cardsMin,
              max: cardsMax,
              minText: cardsMin.toString(),
              maxText: cardsMax.toString(),
              expression: rightConstant.toString(),
              step: 0.1,
              stepText: "0.1",
              unit: cardContainers[inputContainerIndex].content.cards[1]
                ? cardContainers[inputContainerIndex].content.cards[1].unit
                : "obj"
            },
            {
              play: false,
              cardDisabled: false,
              valueEditable: false,
              value: expressionValue ? expressionValue.toString() : "",
              min: cardsMin,
              max: cardsMax,
              minText: cardsMin.toString(),
              maxText: cardsMax.toString(),
              expression: expr,
              step: 0.1,
              stepText: "0.1",
              unit: cardContainers[inputContainerIndex].content.cards[1]
                ? cardContainers[inputContainerIndex].content.cards[1].unit
                : "obj"
            }
          ]
        },
        scope: {}
      };
    } else return false;
  }
}
