import * as math from "mathjs";
import { getNodes } from "../getNodes";
import { autoSetRangeForOneCard } from "../CardStatAutoSettingAlgorithms/autoSetRangeForOneCard";

math.config({
  number: "BigNumber",
  precision: 64
});

export function variableAssignedNumericalExpression(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope,
  specialSymbols
) {
  // The following two lines of code remove the assigned variable from the list of
  // symbol nodes so that the symbol nodes that might appear in the numerical
  // expression (right side of the equation) can be screened to only contain legal
  // numeric symbol characters (e, pi, tau).
  let symbolNodes = getNodes(expr).symbolNodes;
  symbolNodes.shift();

  let varNodeArray = getNodes(expr).symbolNodes.filter(
    node =>
      specialSymbols.allowableVariables.includes(node.name) ||
      /^[a-zA-Z]_[a-zA-Z0-9]$/g.test(node.name)
  );

  let varNode = varNodeArray[0] ? varNodeArray[0].name : 0;

  const singleValueNumericalOutputFunctions = symbolNodes.filter(node => {
    return specialSymbols.singleValueNumericalOutputFunctionTypes.includes(
      node.name
    );
  });

  if (
    getNodes(expr).accessorNodes.length === 0 &&
    getNodes(expr).arrayNodes.length === 0 &&
    getNodes(expr).assignmentNodes.length === 1 &&
    getNodes(expr).assignmentNodes[0].object.name === varNode &&
    getNodes(expr).blockNodes.length === 0 &&
    getNodes(expr).conditionalNodes.length === 0 &&
    getNodes(expr).assignmentNodes.every(node => {
      return node.object.name !== "e" && node.object.name !== "i";
    }) &&
    (getNodes(expr).constantNodes.length >= 1 || symbolNodes.length >= 1) &&
    symbolNodes.every(node => {
      return (
        specialSymbols.realConstants.includes(node.name) ||
        specialSymbols.singleValueNumericalOutputFunctionTypes.includes(
          node.name
        )
      );
      //return node.name === "e" || node.name === "pi" || node.name === "tau";
    }) &&
    getNodes(expr).functionNodes.length ===
      singleValueNumericalOutputFunctions.length &&
    getNodes(expr).functionalAssignmentNodes.length === 0 &&
    getNodes(expr).indexNodes.length === 0 &&
    getNodes(expr).objectNodes.length === 0 &&
    getNodes(expr).operatorNodes.length >= 0 &&
    getNodes(expr).parenthesisNodes.length >= 0 &&
    getNodes(expr).rangeNodes.length === 0 &&
    getNodes(expr).relationalNodes.length === 0 &&
    getNodes(expr).symbolNodes.length >= 0
  ) {
    console.log("VariableAssigned!!");
    try {
      math.eval(expr);
    } catch (error) {
      return false;
    }
    if (cardContainers[inputContainerIndex] && math.eval(expr)) {
      let scopeVar = getNodes(expr).symbolNodes[0].name;
      let cardScope = {};
      cardScope[scopeVar] = math.eval(expr);
      // alter existing cards
      return {
        content: {
          color: inputContainerColor,
          cards: [
            {
              play: false,
              cardDisabled: false,
              valueEditable: false,
              value: cardScope[scopeVar] ? cardScope[scopeVar] : "",
              min: autoSetRangeForOneCard(cardScope[scopeVar]).min,
              max: autoSetRangeForOneCard(cardScope[scopeVar]).max,
              minText: autoSetRangeForOneCard(
                cardScope[scopeVar]
              ).min.toString(),
              maxText: autoSetRangeForOneCard(
                cardScope[scopeVar]
              ).max.toString(),
              expression: scopeVar,
              step: 0.1,
              stepText: "0.1",
              unit: cardContainers[inputContainerIndex].content.cards[0].unit
            }
          ]
        },
        scope: cardScope
      };
    }
  } else return false;
}
