import { determineType } from "./determineType";

export default function getContainer(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope
) {
  return determineType(
    expr,
    inputContainerIndex,
    inputContainerColor,
    cardContainers,
    scope
  );
}
