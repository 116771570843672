import valueToVerticalPixel from "../valueToVerticalPixel";

const drawIntervalLines = (
  ctx,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step
) => {
  ctx.lineWidth = 1;
  ctx.strokeStyle = "grey";
  let i;
  for (i = 0; i < 20; i++) {
    ctx.beginPath();
    ctx.moveTo(
      0,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      )
    );
    ctx.lineTo(
      canvasWidth,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      )
    );
    ctx.closePath();
    ctx.stroke();
  }
};

export default drawIntervalLines;
