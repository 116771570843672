import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "./card";

const CardsContainer = ({
  container,
  changeUnits,
  containerIndex,
  changeValueSlider,
  changeValueInput,
  updateCardValueText,
  updateCardMinText,
  updateCardMaxText,
  updateCardMax,
  updateCardMin,
  updateCardStepText,
  updateCardStep,
  togglePlay,
  handleResize,
  open,
  animateValue
}) => {
  const color = container.content.color;
  return (
    <Grid
      container
      alignItems="flex-end"
      spacing={3}
      style={{ marginBottom: "30px" }}
    >
      {container.content.cards.map((card, index) => {
        return (
          <Card
            key={index}
            cardIndex={index}
            containerIndex={containerIndex}
            changeUnits={changeUnits}
            changeValueSlider={changeValueSlider}
            changeValueInput={changeValueInput}
            updateCardValueText={updateCardValueText}
            updateCardMinText={updateCardMinText}
            updateCardMaxText={updateCardMaxText}
            updateCardMax={updateCardMax}
            updateCardMin={updateCardMin}
            updateCardStepText={updateCardStepText}
            updateCardStep={updateCardStep}
            togglePlay={togglePlay}
            handleResize={handleResize}
            card={card}
            color={color}
            open={open}
            animateValue={animateValue}
          />
        );
      })}
    </Grid>
  );
};

export default CardsContainer;
