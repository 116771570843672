import drawIntervalNumbers from "./drawIntervalNumbers";
import drawIntervalLines from "./drawIntervalLines";
import drawValue from "./drawValue";
import determineStep from "../determineStep";
import determineFirstLinePosition from "../determineFirstLinePosition";

const drawMass = (
  ctx,
  cardValue,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight
) => {
  let minMaxRange = cardMax - cardMin >= 0 ? cardMax - cardMin : 0;
  let step = determineStep(minMaxRange);
  let firstLinePosition = determineFirstLinePosition(
    step,
    cardMin,
    minMaxRange,
    90
  );

  drawIntervalLines(
    ctx,
    cardMin,
    cardMax,
    canvasWidth,
    canvasHeight,
    firstLinePosition,
    step
  );

  drawIntervalNumbers(
    ctx,
    cardMin,
    cardMax,
    canvasWidth,
    canvasHeight,
    firstLinePosition,
    step
  );

  drawValue(
    ctx,
    cardValue,
    cardMin,
    cardMax,
    canvasWidth,
    canvasHeight,
    firstLinePosition,
    step
  );
};

export default drawMass;
