import React from "react";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CompareArrows from "@material-ui/icons/CompareArrows";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  items: {
    display: "flex",
    paddingLeft: "5px",
    paddingRight: "5px",
    color: props => props.color,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
    fontStyle: 300,
    justifyContent: "center",
    alignItems: "center"
  },
  min: {
    textAlign: "center",
    color: props =>
      String(props.card.min) === props.card.minText ? props.color : "lightgrey"
  },
  max: {
    textAlign: "center",
    color: props =>
      String(props.card.max) === props.card.maxText ? props.color : "lightgrey"
  },
  step: {
    textAlign: "center",
    color: props =>
      props.card.step.toString() === props.card.stepText &&
      props.card.valueEditable
        ? props.color
        : "darkgrey",
    borderLeftStyle: "dotted",
    borderLeftColor: "lightgrey",
    borderLeftWidth: "1px"
  },
  input: {
    textAlign: "center"
  }
});

function CardSliderRange(props) {
  const classes = useStyles(props);

  return (
    <Grid container justify="space-around" className={classes.root}>
      <Grid item xs={4}>
        <form
          onSubmit={event => {
            event.preventDefault();
            props.updateCardMin(props.containerIndex, props.cardIndex);
          }}
        >
          <InputBase
            classes={{
              root: classes.items,
              input: classes.min
            }}
            value={props.card.minText}
            onChange={event =>
              props.updateCardMinText(
                props.containerIndex,
                props.cardIndex,
                event.target.value
              )
            }
            inputProps={{ "aria-label": "naked" }}
          />
        </form>
      </Grid>

      <Grid item xs={1}>
        <Typography className={classes.items}>
          <CompareArrows />
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <form
          onSubmit={event => {
            event.preventDefault();
            props.updateCardMax(props.containerIndex, props.cardIndex);
          }}
        >
          <InputBase
            classes={{
              root: classes.items,
              input: classes.max
            }}
            value={props.card.maxText}
            onChange={event =>
              props.updateCardMaxText(
                props.containerIndex,
                props.cardIndex,
                event.target.value
              )
            }
            inputProps={{ "aria-label": "naked" }}
          />
        </form>
      </Grid>
      <Grid item xs={3}>
        <form
          onSubmit={event => {
            event.preventDefault();
            props.updateCardStep(props.containerIndex, props.cardIndex);
          }}
        >
          <InputBase
            classes={{
              root: classes.items,
              input: classes.step
            }}
            value={props.card.stepText}
            disabled={!props.card.valueEditable}
            onChange={event =>
              props.updateCardStepText(
                props.containerIndex,
                props.cardIndex,
                event.target.value
              )
            }
            inputProps={{ "aria-label": "naked" }}
          />
        </form>
      </Grid>
    </Grid>
  );
}

export default CardSliderRange;
