import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DialogCreateAccount from "./dialogCreateAccount";
import DialogSignIn from "./dialogSignIn";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import DialogEditAccount from "./dialogEditAccount";

const useStyles = makeStyles(theme => ({
  saveButton: {
    color: props => props.manifestColors.blue,
    borderColor: props => props.manifestColors.blue,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "11px",
    "&:hover": {
      backgroundColor: props => props.manifestColors.blue,
      color: "white"
    }
  },
  createAccountButton: {
    color: props => props.manifestColors.red,
    borderColor: props => props.manifestColors.red,
    backgroundColor: props => props.manifestColors.red,
    minWidth: "145px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "11px",
    "&:hover": {
      backgroundColor: props => props.manifestColors.raspberry,
      borderColor: props => props.manifestColors.raspberry,
      color: "white"
    }
  },
  signInButton: {
    color: props => props.manifestColors.slate,
    borderColor: props => props.manifestColors.slate,
    minWidth: "80px",
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "11px",
    "&:hover": {
      backgroundColor: props => props.manifestColors.slate,
      color: "white"
    }
  },
  userName: {
    fontFamily: "Montserrat, sans-serif"
  },
  accountDisplayWrapper: {
    paddingRight: "30px",
    paddingLeft: "30px",
    display: "flex",
    height: "30px",
    alignItems: "center",
    cursor: "pointer"
  },
  accountMenu: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "lightgrey",
    padding: "20px",
    paddingTop: "10px",
    fontFamily: "Montserrat, sans-serif"
  },
  userNameInMenu: { color: "grey" },
  emailAddress: {
    color: "grey",
    fontSize: "10px",
    marginTop: "-15px"
  },
  accountMenuOption: {
    cursor: "pointer",
    "&:hover": {
      color: props => props.manifestColors.blue
    }
  }
}));

const AccountDisplay = props => {
  const classes = useStyles(props);

  const [signedIn, setSignedIn] = React.useState();

  function handleSignOutClick() {
    setSignedIn(false);
  }

  let userName = "harrytomalley";

  const [dialogCreateAccountOpen, setDialogCreateAccountOpen] = React.useState(
    false
  );
  const [dialogSignInOpen, setDialogSignInOpen] = React.useState(false);
  const [dialogEditAccountOpen, setDialogEditAccountOpen] = React.useState(
    false
  );

  //popover for account action menu

  const [anchorElement, setAnchorElement] = React.useState(null);

  function handleAccountMenuClick(event) {
    setAnchorElement(event.currentTarget);
  }

  function handleAccountMenuClose() {
    setAnchorElement(null);
  }

  const accountMenuOpen = Boolean(anchorElement);
  const accountMenuID = accountMenuOpen ? "simple-popover" : undefined;

  //end popover

  function handleSignInClose() {
    setDialogSignInOpen(false);
  }
  function handleSignInOpen() {
    setDialogSignInOpen(true);
  }
  function handleSignInSubmit() {
    setSignedIn(true);
  }

  function handleCreateAccountClose() {
    setDialogCreateAccountOpen(false);
  }
  function handleCreateAccountOpen() {
    setDialogCreateAccountOpen(true);
  }

  function handleEditAccountClose() {
    setDialogEditAccountOpen(false);
  }
  function handleEditAccountOpen() {
    setDialogEditAccountOpen(true);
  }

  console.log("SIGNED IN!!!", signedIn);

  const accountDisplay = signedIn ? (
    <div>
      <span
        className={classes.accountDisplayWrapper}
        onClick={handleAccountMenuClick}
      >
        <p className={classes.userName}>{userName}</p>
        <ArrowDropDownIcon />
      </span>
      <Popover
        id={accountMenuID}
        open={accountMenuOpen}
        anchorEl={anchorElement}
        onClose={handleAccountMenuClose}
        elevation={1}
        PaperProps={{ className: classes.accountMenu }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <p className={classes.userNameInMenu}>harrytomalley</p>
        <p className={classes.emailAddress}>harrytomalley@gmail.com</p>
        <p
          className={classes.accountMenuOption}
          onClick={handleEditAccountOpen}
        >
          Account Settings
        </p>
        <DialogEditAccount
          dialogEditAccountOpen={dialogEditAccountOpen}
          handleEditAccountClose={handleEditAccountClose}
          manifestColors={props.manifestColors}
        />
        <p className={classes.accountMenuOption} onClick={handleSignOutClick}>
          Sign Out
        </p>
      </Popover>
    </div>
  ) : (
    <span className={classes.accountDisplayWrapper}>
      <Button
        variant="outlined"
        className={classes.createAccountButton}
        onClick={handleCreateAccountOpen}
      >
        CREATE ACCOUNT
      </Button>
      <DialogCreateAccount
        dialogCreateAccountOpen={dialogCreateAccountOpen}
        handleCreateAccountClose={handleCreateAccountClose}
        manifestColors={props.manifestColors}
      />
      <p style={{ fontFamily: "Montserrat, sans-serif" }}>or</p>
      <Button
        variant="outlined"
        className={classes.signInButton}
        onClick={handleSignInOpen}
      >
        SIGN IN
      </Button>
      <DialogSignIn
        dialogSignInOpen={dialogSignInOpen}
        handleSignInClose={handleSignInClose}
        handleSignInSubmit={handleSignInSubmit}
        manifestColors={props.manifestColors}
      />
    </span>
  );
  return accountDisplay;
};

export default AccountDisplay;
