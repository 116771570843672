import valueToVerticalPixel from "../valueToVerticalPixel";

const drawValue = (ctx, val, cardMin, cardMax, canvasWidth, canvasHeight) => {
  ctx.fillStyle = "black";
  const arcRadius = 10;
  ctx.beginPath();
  ctx.arc(
    canvasWidth / 2,
    valueToVerticalPixel(val, cardMin, cardMax, canvasHeight),
    arcRadius,
    0,
    2 * Math.PI
  );
  ctx.closePath();
  ctx.fill();
};

export default drawValue;
