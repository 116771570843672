import stepToMultiDirectionalPixel from "../stepToMultiDirectionalPixel";
import * as math from "mathjs";
import determineFirstLinePosition from "../determineFirstLinePosition";

const drawIntervalNums = (
  ctx,
  cardMin,
  cardMax,
  minMaxRange,
  canvasWidth,
  canvasHeight,
  step,
  theta
) => {
  let firstLinePosition = determineFirstLinePosition(
    step,
    cardMin,
    minMaxRange,
    90
  );
  let maxTextWidth = 0;
  let tempTextWidth = 0;
  for (let k = -10; k < 40; k++) {
    tempTextWidth = ctx.measureText(
      math.eval(`${firstLinePosition} + ${step} * ${k}`).toString()
    ).width;
    if (tempTextWidth > maxTextWidth) {
      maxTextWidth = tempTextWidth;
    }
  }
  //flip the canvas back over so the text is upright and rotate it by -theta
  ctx.scale(1, -1);
  ctx.rotate(-theta);

  if (theta > math.pi / 2 && theta < (3 * math.pi) / 2) {
    ctx.rotate(math.pi);
  }

  for (let j = -10; j < 40; j++) {
    let textDistanceFromOrigin =
      theta > math.pi / 2 && theta < (3 * math.pi) / 2
        ? -stepToMultiDirectionalPixel(
            firstLinePosition + step * j,
            cardMin,
            cardMax,
            canvasHeight
          )
        : stepToMultiDirectionalPixel(
            firstLinePosition + step * j,
            cardMin,
            cardMax,
            canvasHeight
          );
    //draw lines from one of the numbers to the other
    ctx.lineWidth = firstLinePosition + step * j === 0 ? 3 : 1;
    ctx.strokeStyle = firstLinePosition + step * j === 0 ? "black" : "grey";
    ctx.beginPath();
    ctx.moveTo(textDistanceFromOrigin, canvasWidth * 2);
    ctx.lineTo(textDistanceFromOrigin, -canvasWidth * 2);
    ctx.closePath();
    ctx.stroke();
  }
  for (let i = -10; i < 40; i++) {
    let textDistanceFromOrigin =
      theta > math.pi / 2 && theta < (3 * math.pi) / 2
        ? -stepToMultiDirectionalPixel(
            firstLinePosition + step * i,
            cardMin,
            cardMax,
            canvasHeight
          )
        : stepToMultiDirectionalPixel(
            firstLinePosition + step * i,
            cardMin,
            cardMax,
            canvasHeight
          );

    //get text width
    let textWidth = ctx.measureText(
      math.eval(`${firstLinePosition} + ${step} * ${i}`).toString()
    ).width;

    const textHeight = 20;
    const textPadding = 10;

    let intervalNumberDistanceFromCenterA = 80;
    let intervalNumberDistanceFromCenterB = 120;
    let intervalNumberDistanceFromCenterC = 160;
    let intervalNumberDistanceFromCenterD = 200;

    if (maxTextWidth < 27) {
      intervalNumberDistanceFromCenterA = 120;
      intervalNumberDistanceFromCenterB = 120;
      intervalNumberDistanceFromCenterC = 120;
      intervalNumberDistanceFromCenterD = 120;
    }

    if (maxTextWidth >= 27 && maxTextWidth < 50) {
      intervalNumberDistanceFromCenterA = 120;
      intervalNumberDistanceFromCenterB = 120;
      intervalNumberDistanceFromCenterC = 160;
      intervalNumberDistanceFromCenterD = 160;
    }

    if (maxTextWidth >= 50) {
      intervalNumberDistanceFromCenterA = 80;
      intervalNumberDistanceFromCenterB = 120;
      intervalNumberDistanceFromCenterC = 160;
      intervalNumberDistanceFromCenterD = 200;
    }

    let textColor = firstLinePosition + step * i === 0 ? "black" : "grey";

    if ((i + 10) % 8 === 0) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        -intervalNumberDistanceFromCenterB - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        -intervalNumberDistanceFromCenterB
      );
    }

    if ((i + 10) % 8 === 2) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        -intervalNumberDistanceFromCenterD - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        -intervalNumberDistanceFromCenterD
      );
    }

    if ((i + 10) % 8 === 4) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        -intervalNumberDistanceFromCenterA - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        -intervalNumberDistanceFromCenterA
      );
    }

    if ((i + 10) % 8 === 6) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        -intervalNumberDistanceFromCenterC - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        -intervalNumberDistanceFromCenterC
      );
    }

    if ((i + 10) % 8 === 1) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        intervalNumberDistanceFromCenterB - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        intervalNumberDistanceFromCenterB
      );
    }

    if ((i + 10) % 8 === 3) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        intervalNumberDistanceFromCenterD - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        intervalNumberDistanceFromCenterD
      );
    }

    if ((i + 10) % 8 === 5) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        intervalNumberDistanceFromCenterA - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        intervalNumberDistanceFromCenterA
      );
    }

    if ((i + 10) % 8 === 7) {
      //draw the white background rectangles for the text
      ctx.fillStyle = "white";
      ctx.fillRect(
        textDistanceFromOrigin - textWidth / 2 - textPadding,
        intervalNumberDistanceFromCenterC - textPadding * 2,
        textWidth + textPadding * 2,
        textHeight + textPadding * 2
      );
      //draw the text on one side of the value
      ctx.font = "24px Montserrat";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = textColor;
      ctx.fillText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
        textDistanceFromOrigin,
        intervalNumberDistanceFromCenterC
      );
    }
  }
  ctx.rotate(-theta);
  if (theta > math.pi / 2 && theta < (3 * math.pi) / 2) {
    ctx.rotate(-math.pi);
  }
};

export default drawIntervalNums;
