import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/lab/Slider";
import { fade } from "@material-ui/core/styles/colorManipulator";

const sliderColor = "#000000";

const useStyles = makeStyles({
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: sliderColor,
    boxShadow: `0px 0px 0px ${4}px ${fade(sliderColor, 0.16)}`,
    "&:focus": {
      boxShadow: `0px 0px 0px ${8}px ${fade(sliderColor, 0.16)}`
    },
    "&:hover": {
      boxShadow: `0px 0px 0px ${8}px ${fade(sliderColor, 0.16)}`
    },
    "&$active": {
      boxShadow: `0px 0px 0px ${8 * 2}px ${fade(sliderColor, 0.16)}`
    },
    "$disabled &": {
      backgroundColor: "lightgrey",
      boxShadow: `0px 0px 0px 0px`,
      height: 8,
      width: 8
    }
  },
  track: {
    backgroundColor: sliderColor,
    height: 2,
    "$disabled &": {
      backgroundColor: "lightgrey"
    }
  },
  rail: {
    backgroundColor: sliderColor,
    "$disabled &": {
      backgroundColor: "lightgrey"
    }
  },
  active: {},
  disabled: {
    height: 2,
    color: "pink",
    track: {
      backgroundColor: "lightgrey",
      height: 2
    },
    rail: {
      backgroundColor: "pink"
    }
  }
});

function CardSlider({ card, containerIndex, cardIndex, changeValueSlider }) {
  const classes = useStyles();

  return (
    <Slider
      disabled={!card.valueEditable}
      value={card.value}
      step={card.step}
      aria-labelledby="label"
      min={card.min}
      max={card.max}
      onChange={(e, val) =>
        changeValueSlider(containerIndex, cardIndex, val, card.step)
      }
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        rail: classes.rail,
        active: classes.active,
        jumped: classes.jumped,
        focused: classes.focused,
        disabled: classes.disabled
      }}
    />
  );
}

export default CardSlider;
