import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import * as math from "mathjs";
import MathJax from "react-mathjax2";
import IconButton from "@material-ui/core/IconButton";
import LensOutlinedIcon from "@material-ui/icons/LensOutlined";

const useStyles = makeStyles({
  formulaInput: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    height: "56px"
  },
  inputIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 40,
    marginLeft: "8px"
  },
  clearIconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 40,
    width: 40,
    marginRight: "13px"
  },
  outputArrowContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "11px",
    marginTop: "-15px",
    marginLeft: "8px"
  }
});

const InputContainer = ({
  inputContainerColor,
  createCardsFromUserInput,
  inputContainerIndex,
  cardContainers,
  inputContainers,
  insertInputContainer,
  removeInputContainer
}) => {
  let renderedInput =
    inputContainers[inputContainerIndex] &&
    inputContainers[inputContainerIndex].status === "active"
      ? math
          .parse(inputContainers[inputContainerIndex].input)
          .toTex()
          .replace(/~/g, "")
          .replace(/:=/g, "=")
          .replace(/\\_/g, "_")
          .replace(/[^\\]Delta/g, "\\Delta ")
      : "";

  let containerIcon =
    inputContainers[inputContainerIndex].status === "active" ? (
      <CheckCircleIcon style={{ color: inputContainerColor }} />
    ) : (
      <LensOutlinedIcon style={{ color: "grey" }} />
    );
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.formulaInput}>
        <div className={classes.inputIconContainer}>{containerIcon}</div>
        <Input
          value={inputContainers[inputContainerIndex].rawInput}
          autoFocus
          inputProps={{ spellCheck: "false" }}
          fullWidth
          disableUnderline
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            fontFamily: "Inconsolata, monospace",
            fontStyle: 400,
            fontSize: "20px"
            //borderBottom: "solid 1px lightgrey"
          }}
          onChange={event => {
            createCardsFromUserInput(
              event.target.value,
              inputContainerIndex,
              inputContainerColor,
              cardContainers
            );
          }}
          onKeyPress={event => {
            insertInputContainer(event.key, inputContainerIndex);
          }}
        />
        <div className={classes.clearIconContainer}>
          <IconButton
            onClick={() => removeInputContainer(inputContainerIndex)}
            style={{ marginRight: "247px" }}
          >
            <ClearIcon style={{ color: "grey" }} />
          </IconButton>
        </div>
      </div>
      {inputContainers[inputContainerIndex] &&
      inputContainers[inputContainerIndex].status === "active" ? (
        <div>
          <div className={classes.outputArrowContainer}>
            <SubdirectoryArrowRightIcon
              style={{ color: inputContainerColor }}
            />

            <MathJax.Context input="tex">
              <div>
                <div style={{ paddingLeft: "10px", fontSize: "17px" }}>
                  <MathJax.Node>{renderedInput}</MathJax.Node>
                </div>
              </div>
            </MathJax.Context>
          </div>
        </div>
      ) : null}
      <Divider />
    </React.Fragment>
  );
};

export default InputContainer;
