import drawIntervalLines from "./drawIntervalLines";

const drawNumberOfObjects = (
  ctx,
  cardValue,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  color
) => {
  drawIntervalLines(
    ctx,
    cardMin,
    cardMax,
    canvasWidth,
    canvasHeight,
    cardValue,
    color
  );
};

export default drawNumberOfObjects;
