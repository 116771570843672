import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles({
  select: {
    //color: props => props.color,
    color: "transparent",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1px",
    fontStyle: 300,
    "& :focus": { backgroundColor: "transparent" }
  },
  icon: {
    fill: props => props.color
  },
  unitMenuPaper: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "grey"
  },
  unitListItem: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "14px",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  listPadding: {
    padding: "0px"
  }
});

const CardUnits = props => {
  const classes = useStyles(props);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <form autoComplete="off">
        <FormControl style={{ margin: 10 }}>
          <Select
            MenuProps={{
              classes: {
                paper: classes.unitMenuPaper
              }
            }}
            value={props.card.unit}
            onChange={event =>
              props.changeUnits(
                props.containerIndex,
                props.cardIndex,
                event.target.value
              )
            }
            disableUnderline
            className={classes.select}
            inputProps={{
              name: "units",
              id: "age-simple",
              classes: {
                icon: classes.icon
              }
            }}
          >
            <MenuItem className={classes.unitListItem} value="temp">
              temp
            </MenuItem>
            <MenuItem dense className={classes.unitListItem} value="lum">
              lum
            </MenuItem>
            <MenuItem className={classes.unitListItem} value="time">
              time
            </MenuItem>
            <MenuItem className={classes.unitListItem} value="mass">
              mass
            </MenuItem>
            <MenuItem className={classes.unitListItem} value="length">
              length
            </MenuItem>
            <MenuItem className={classes.unitListItem} value="length velocity">
              length velocity
            </MenuItem>
            <MenuItem
              className={classes.unitListItem}
              value="length acceleration"
            >
              length acceleration
            </MenuItem>
            <MenuItem className={classes.unitListItem} value="obj">
              obj
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    </div>
  );
};

export default CardUnits;
