import drawIntervalNums from "./drawIntervalNums";
import drawValue from "./drawValue";
import determineStep from "../determineStep";
import * as math from "mathjs";
import stepToMultiDirectionalPixel from "../stepToMultiDirectionalPixel";

const drawMultiDirectional1DPoint = (
  ctx,
  cardValue,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight
) => {
  ctx.translate(canvasWidth / 2, canvasHeight / 2);
  ctx.scale(1, -1);

  cardMax = cardMax * 5;
  cardMin = cardMin * 5;

  let rangeAverage = stepToMultiDirectionalPixel(
    (cardMax + cardMin) / 2,
    cardMin,
    cardMax,
    canvasHeight
  );

  //let theta = 0.25 * math.pi;
  let theta = 0.5 * math.pi;

  let xTranslation = -rangeAverage * math.cos(theta);
  let yTranslation = -rangeAverage * math.sin(theta);

  ctx.translate(xTranslation, yTranslation);

  let minMaxRange = cardMax - cardMin >= 0 ? cardMax - cardMin : 0;
  let step = determineStep(minMaxRange);

  drawIntervalNums(
    ctx,
    cardMin,
    cardMax,
    minMaxRange,
    canvasWidth,
    canvasHeight,
    step,
    theta
  );

  drawValue(ctx, cardValue, cardMin, cardMax, canvasHeight, theta);
};

export default drawMultiDirectional1DPoint;
