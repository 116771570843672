import React from "react";
import { Route } from "react-router-dom";
import "./App.css";
import ManifestVisualCalc from "./components/manifestVisualCalc";
import LoginForm from "./components/loginForm";

function App() {
  return (
    <React.Fragment>
      <Route path="/login" exact component={LoginForm} />
      <Route path="/logins" exact render={() => <LoginForm />} />
      <Route
        path="/simulation/:simulationID"
        exact
        render={(props) => <ManifestVisualCalc {...props} />}
      />
      <Route
        path="/"
        exact
        render={(props) => <ManifestVisualCalc {...props} />}
      />
    </React.Fragment>
  );
}

export default App;
