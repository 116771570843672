import { numericalExpression } from "./ExpressionTypeScreeners/numericalExpression";
import { variableAssignedNumericalExpression } from "./ExpressionTypeScreeners/variableAssignedNumericalExpression";
import { singleVariable } from "./ExpressionTypeScreeners/singleVariable";
import { multiInputSingleOutputEquation } from "./ExpressionTypeScreeners/multiInputSingleOutputEquation";
import { algebraicExpression } from "./ExpressionTypeScreeners/algebraicExpression";
import { numericalExpressionAdditionSubtraction } from "./ExpressionTypeScreeners/numericalExpressionAdditionSubtraction";
import { numericalExpressionMultiplication } from "./ExpressionTypeScreeners/numericalExpressionMultiplication";
import { numericalExpressionDivision } from "./ExpressionTypeScreeners/numericalExpressionDivision";
import { ratioOfConstants } from "./ExpressionTypeScreeners/ratioOfConstants";

export function determineType(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope
) {
  const specialSymbols = {
    realConstants: ["pi", "tau", "phi", "e"],
    allowableVariables: [
      "a",
      "b",
      "c",
      "d",
      "f",
      "g",
      "h",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "alpha",
      "beta",
      "gamma",
      "delta",
      "epsilon",
      "zeta",
      "eta",
      "theta",
      "iota",
      "kappa",
      "lambda",
      "mu",
      "nu",
      "xi",
      "omicron",
      "rho",
      "sigma",
      "upsilon",
      "chi",
      "psi",
      "omega",
      "Alpha",
      "Beta",
      "Gamma",
      "Delta",
      "Epsilon",
      "Zeta",
      "Eta",
      "Theta",
      "Iota",
      "Kappa",
      "Lambda",
      "Mu",
      "Pi",
      "Tau",
      "Phi",
      "Nu",
      "Xi",
      "Omicron",
      "Rho",
      "Sigma",
      "Upsilon",
      "Chi",
      "Psi",
      "Omega"
    ],
    singleValueNumericalOutputFunctionTypes: [
      "add",
      "abs",
      "ceil",
      "divide",
      "exp",
      "expm1",
      "fix",
      "floor",
      "gcd",
      "hypot",
      "lcm",
      "log",
      "log10",
      "log1p",
      "log2",
      "mod",
      "multiply",
      "norm",
      "nthRoot",
      "pow",
      "round",
      "sign",
      "sqrt",
      "square",
      "subtract",
      "unaryMinus",
      "unaryPlus",
      "acos",
      "acosh",
      "acot",
      "acoth",
      "acsc",
      "acsch",
      "asec",
      "asech",
      "asin",
      "asinh",
      "atan",
      "atan2",
      "atanh",
      "cos",
      "cosh",
      "cot",
      "coth",
      "csc",
      "csch",
      "sec",
      "sech",
      "sin",
      "sinh",
      "tan",
      "tanh",
      "mad",
      "max",
      "mean",
      "median",
      "min",
      "mode",
      "prod",
      "std",
      "sum",
      "variance"
    ]
  };

  if (
    numericalExpressionAdditionSubtraction(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return numericalExpressionAdditionSubtraction(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    numericalExpressionMultiplication(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return numericalExpressionMultiplication(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    numericalExpressionDivision(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return numericalExpressionDivision(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    numericalExpression(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return numericalExpression(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    ratioOfConstants(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return ratioOfConstants(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    variableAssignedNumericalExpression(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return variableAssignedNumericalExpression(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    singleVariable(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return singleVariable(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    multiInputSingleOutputEquation(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return multiInputSingleOutputEquation(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else if (
    algebraicExpression(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    )
  ) {
    return algebraicExpression(
      expr,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope,
      specialSymbols
    );
  } else {
    return {
      content: {
        color: "black",
        cards: [
          {
            play: false,
            cardDisabled: true,
            valueEditable: false,
            value: 8,
            min: 0,
            max: 10,
            valueText: "8",
            minText: "0",
            maxText: "10",
            expression: 1,
            step: 0.1,
            stepText: "0.1",
            unit: "obj"
          }
        ]
      },
      scope: {}
    };
  }
}
