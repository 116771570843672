import React from "react";
import { makeStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles({
  items: {
    display: "flex",
    paddingLeft: "5px",
    paddingRight: "5px",
    color: props =>
      props.card.valueText === props.card.value.toString()
        ? props.color
        : "lightgrey",
    fontFamily: "Montserrat, sans-serif",
    justifyContent: "center",
    fontSize: "15px",
    alignItems: "center"
  },
  input: {
    textAlign: "center"
  }
});

const CardValue = props => {
  const classes = useStyles(props);
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        props.changeValueInput(props.containerIndex, props.cardIndex);
      }}
    >
      <InputBase
        classes={{ root: classes.items, input: classes.input }}
        value={props.card.valueText}
        disabled={!props.card.valueEditable}
        onChange={event => {
          props.updateCardValueText(
            props.containerIndex,
            props.cardIndex,
            event.target.value
          );
        }}
        inputProps={{ "aria-label": "naked" }}
      />
    </form>
  );
};

export default CardValue;
