import * as math from "mathjs";

math.config({
  number: "BigNumber",
  precision: 64
});

export function autoSetRangeForOneCard(value) {
  let cardMax;
  let cardMin;
  try {
    let bigValue = math.bignumber(value);
    const signOfValue = math.bignumber(math.sign(bigValue));
    const magnitudeOfValue = math.bignumber(math.abs(bigValue));
    const log10OfValue =
      magnitudeOfValue.toNumber() === 0
        ? 0
        : math.bignumber(math.log10(magnitudeOfValue));
    const flooredLog10OfValue = math.bignumber(math.floor(log10OfValue));
    const scaledValue = math.bignumber(
      magnitudeOfValue / 10 ** flooredLog10OfValue
    );
    const flooredScaledValue = math.bignumber(math.floor(scaledValue));
    if (signOfValue >= 0) {
      cardMin = 0;
      cardMax = (flooredScaledValue.toNumber() + 2) * 10 ** flooredLog10OfValue;
    } else if (signOfValue < 0) {
      cardMin =
        signOfValue *
        ((flooredScaledValue.toNumber() + 2) * 10 ** flooredLog10OfValue);
      cardMax = 0;
    }

    return { min: cardMin, max: cardMax };
  } catch (error) {
    console.log(error);
    return { min: 0, max: 10 };
  }
}
