import valueToHorizontalPixel from "../valueToHorizontalPixel";
import * as math from "mathjs";

const drawIntervalNumbers = (
  ctx,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step,
  val
) => {
  const numberMargin = 0;

  //draw lightgrey line at 0

  ctx.strokeStyle = "lightgrey";
  ctx.lineWidth = 1;
  ctx.setLineDash([5]);
  ctx.beginPath();
  ctx.moveTo(
    valueToHorizontalPixel(0, cardMin, cardMax, canvasWidth),
    0.18 * canvasHeight
  );
  ctx.lineTo(
    valueToHorizontalPixel(0, cardMin, cardMax, canvasWidth),
    canvasHeight / 2
  );
  ctx.closePath();
  ctx.stroke();

  //draw line at value

  ctx.strokeStyle = "lightgrey";
  ctx.lineWidth = 1;
  ctx.setLineDash([5]);
  ctx.beginPath();
  ctx.moveTo(
    valueToHorizontalPixel(val, cardMin, cardMax, canvasWidth),
    0.18 * canvasHeight
  );
  ctx.lineTo(
    valueToHorizontalPixel(val, cardMin, cardMax, canvasWidth),
    canvasHeight / 2
  );
  ctx.closePath();
  ctx.stroke();
  ctx.strokeStyle = "grey";
  ctx.setLineDash([]);

  for (let i = 0; i < 20; i++) {
    //get text width
    var outerTextWidth = ctx.measureText(
      math.eval(`${firstLinePosition} + ${step} * ( 4 * ${i} - 4 )`).toString()
    );

    var innerTextWidth = ctx.measureText(
      math.eval(`${firstLinePosition} + ${step} * ( 4 * ${i} - 2 )`).toString()
    );

    //create white background rectangles for text
    ctx.fillStyle = "white";

    // draw top row of top rectangles and lines that connect them to the timeline
    ctx.fillRect(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 4),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin,
      //(150 / 500) * canvasHeight,  //<----this scales the position multiplicatively
      canvasHeight / 2 - 100, //<----this scales the position by adding/subtracting from center line
      outerTextWidth.width + 2 * numberMargin,
      40
    );
    ctx.beginPath();
    ctx.moveTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 4),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      //(190 / 500) * canvasHeight  //<----this scales the position multiplicatively
      canvasHeight / 2 - 60 //<----this scales the position by adding/subtracting from center line
    );
    ctx.lineTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 4),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      canvasHeight / 2
    );
    ctx.closePath();
    ctx.stroke();

    // draw bottom row of top rectangles and lines that connect them to the timeline
    ctx.fillRect(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 2),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        innerTextWidth.width / 2 -
        numberMargin,
      //(190 / 500) * canvasHeight,  //<----this scales the position multiplicatively
      canvasHeight / 2 - 60, //<----this scales the position by adding/subtracting from center line
      innerTextWidth.width + 2 * numberMargin,
      40
    );

    ctx.beginPath();
    ctx.moveTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 2),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      //(230 / 500) * canvasHeight  //<----this scales the position multiplicatively
      canvasHeight / 2 - 20 //<----this scales the position by adding/subtracting from center line
    );
    ctx.lineTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 2),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      canvasHeight / 2
    );
    ctx.closePath();
    ctx.stroke();

    // draw bottom row of bottom rectangles and lines that connect them to the timeline
    ctx.fillRect(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 1),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin,
      //((500 - 190) / 500) * canvasHeight,  //<----this scales the position multiplicatively
      canvasHeight / 2 + 60, //<----this scales the position by adding/subtracting from center line
      outerTextWidth.width + 2 * numberMargin,
      40
    );

    ctx.beginPath();
    ctx.moveTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 1),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      //((500 - 190) / 500) * canvasHeight  //<----this scales the position multiplicatively
      canvasHeight / 2 + 60 //<----this scales the position by adding/subtracting from center line
    );
    ctx.lineTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 1),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      canvasHeight / 2
    );
    ctx.closePath();
    ctx.stroke();

    // draw top row of bottom rectangles and lines that connect them to the timeline
    ctx.fillRect(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 3),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        innerTextWidth.width / 2 -
        numberMargin,
      //((500 - 230) / 500) * canvasHeight,  //<----this scales the position multiplicatively
      canvasHeight / 2 + 20, //<----this scales the position by adding/subtracting from center line
      innerTextWidth.width + 2 * numberMargin,
      40
    );

    ctx.beginPath();
    ctx.moveTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 3),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      //((500 - 230) / 500) * canvasHeight  //<----this scales the position multiplicatively
      canvasHeight / 2 + 20 //<----this scales the position by adding/subtracting from center line
    );
    ctx.lineTo(
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 3),
        cardMin,
        cardMax,
        canvasWidth
      ) -
        outerTextWidth.width / 2 -
        numberMargin +
        (outerTextWidth.width + 2 * numberMargin) / 2,
      canvasHeight / 2
    );
    ctx.closePath();
    ctx.stroke();

    //format text
    ctx.font = "24px Montserrat";
    ctx.textBaseline = "middle";

    //format text for top
    ctx.textAlign = "center";
    ctx.fillStyle = "grey";

    //draw top row of top text
    ctx.fillText(
      math.eval(`${firstLinePosition} + ${step} * ( 4 * ${i} - 4 )`).toString(),
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 4),
        cardMin,
        cardMax,
        canvasWidth
      ),
      //(170 / 500) * canvasHeight  //<----this scales the position multiplicatively
      canvasHeight / 2 - 80 //<----this scales the position by adding/subtracting from center line
    );

    //draw bottom row of top text
    ctx.fillText(
      math.eval(`${firstLinePosition} + ${step} * ( 4 * ${i} - 2 )`).toString(),
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 2),
        cardMin,
        cardMax,
        canvasWidth
      ),
      //(210 / 500) * canvasHeight  //<----this scales the position multiplicatively
      canvasHeight / 2 - 40 //<----this scales the position by adding/subtracting from center line
    );

    // format text for bottom
    ctx.textAlign = "center";
    ctx.fillStyle = "grey";

    //draw bottom row of bottom text
    ctx.fillText(
      math.eval(`${firstLinePosition} + ${step} * ( 4 * ${i} - 1 )`).toString(),
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 1),
        cardMin,
        cardMax,
        canvasWidth
      ),
      //((500 - 170) / 500) * canvasHeight //<----this scales the position multiplicatively
      canvasHeight / 2 + 80 //<----this scales the position by adding/subtracting from center line
    );

    //draw top row of bottom text
    ctx.fillText(
      math.eval(`${firstLinePosition} + ${step} * ( 4 * ${i} - 3 )`).toString(),
      valueToHorizontalPixel(
        firstLinePosition + step * (4 * i - 3),
        cardMin,
        cardMax,
        canvasWidth
      ),
      //((500 - 210) / 500) * canvasHeight  //<----this scales the position multiplicatively
      canvasHeight / 2 + 40 //<----this scales the position by adding/subtracting from center line
    );
  }
};

export default drawIntervalNumbers;
