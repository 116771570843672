import stepToMultiDirectionalPixel from "../stepToMultiDirectionalPixel";

const drawValue = (ctx, val, cardMin, cardMax, canvasHeight, theta) => {
  //get the length as the correct number of canvas pixels
  let rayLength = stepToMultiDirectionalPixel(
    val,
    cardMin,
    cardMax,
    canvasHeight
  );

  ctx.rotate(theta);

  ctx.fillStyle = "black";

  /*
  //draw line from origin to point
  ctx.strokeStyle = "black";
  ctx.lineWidth = 4;
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(rayLength, 0);
  ctx.closePath();
  ctx.stroke();
  */

  //draw main point
  ctx.strokeStyle = "black";
  ctx.lineWidth = 4;
  for (let i = 1; i < 6; i++) {
    ctx.beginPath();
    ctx.moveTo(rayLength * i, -canvasHeight * 0.05);
    ctx.lineTo(rayLength * i, canvasHeight * 0.05);
    ctx.closePath();
    ctx.stroke();
  }

  ctx.rotate(-theta);
};

export default drawValue;
