import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TopBar from "./topBar";
import TopBarMobile from "./topBarMobile";
import CardsArea from "./cardsArea";
import CardsAreaMobile from "./cardsAreaMobile";
import InputsDrawer from "./inputsDrawer";
import InputsDrawerMobile from "./inputsDrawerMobile";
import * as math from "mathjs";
import getContainer from "../ExpressionProcessing/getContainer";
import AdminDrawer from "./adminDrawer";
import { databaseUsers } from "../dataBase/databaseUsers";
import { databaseSimulations } from "../dataBase/databaseSimulations";

/*
  
CONFIGURATION

*/

// this makes the default number type in mathjs a BigNumber
math.config({
  number: "BigNumber",
  precision: 64,
});

/*
  
VARIABLES

*/

/*
  
STEP 1: ESTABLISH COLORS AND STYLES

*/

// these are the five Manifest colors that input containers and cards can be
const manifestColors = {
  red: "#FF6B6B",
  blue: "#4ECDC4",
  green: "#79B473",
  slate: "#577590",
  raspberry: "#950952",
};

// the useStyles method returns the CSS classes defined as objects within it
const useStyles = makeStyles({
  root: {
    display: "flex",
  },
});

/*
  
STEP 2: CONNECT TO DATABASE

*/

// the simulations and users arrays are the fake database until I have a real one set up

//this pulls the users from the fake users database file
const users = databaseUsers();

//this pulls the simulations from the fake users database file
const simulations = databaseSimulations();

/*
  
BEGIN MAIN COMPONENT FUNCTION

*/

// Manifest Visual Calc is the main component function
const ManifestVisualCalc = ({ history, location, match }) => {
  // the classes variable contains the CSS styles defined in the useStyles method above
  const classes = useStyles();

  /*
    
  STEP 3: GET USER FROM DATABASE IF LOGGED IN

  */

  // this is just a placeholder until I think throught the user logic more.
  const currentUser = {
    userName: "homalley",
    name: "Harry",
    simulations: [1930, 1932],
  };

  /*
    
  STEP 4: GET SIMULATION FROM DATABASE IF A FILE IS BEING OPENED OR SET 
  TO DEFAULT SIMULATION IF A NEW, BLANK SIMULATION IS BEING REQUESTED

  */

  // if the URL has a simulationID parameter, then find the simulation in the database
  // with that parameter and make "currentSimulation" that simulation.  Otherwise, make
  // "currentSimulation" equal to the default simulation.
  const currentSimulation = match.params.simulationID
    ? simulations.filter(
        (simulation) =>
          simulation.simulationID.toString() === match.params.simulationID
      )[0]
    : {
        simulationID: 0,
        name: "Untitled",
        open: true,
        inputContainers: [
          { status: "invalid", input: "", rawInput: "", message: "" },
        ],
        scope: {},
        cardContainers: [
          {
            content: {
              color: "black",
              cards: [
                {
                  play: false,
                  cardDisabled: true,
                  valueEditable: false,
                  value: 8,
                  min: 0,
                  max: 10,
                  valueText: "8",
                  minText: "0",
                  maxText: "10",
                  expression: "8",
                  step: 0.1,
                  stepText: "0.1",
                  unit: "obj",
                },
              ],
            },
            scope: {},
          },
        ],
      };

  /*
    
  STEP 5: ESTABLISH INPUT CONTAINERS AND CARD CONTAINERS

  */

  // "inputContainers" is an array that houses all of the basic information
  // for each input container in the input side bar
  const [inputContainers, setInputContainers] = useState(
    currentSimulation.inputContainers
  );

  // "cardContainers" houses all the base information that is used to display
  // the simulation cards to the user.  It is an array of objects.
  // Each container in the array has a color that matches its input container, a
  // collection of cards, and local scope, which contains the variables associated
  // with the cards in the container and their current values.
  const [cardContainers, setCardContainers] = useState(
    currentSimulation.cardContainers
  );

  /*
    
  STEP 6: ESTABLISH SCOPE

  */

  // "scope" is a single object that links the mathematical variables
  // in the simulation with their current value
  const [scope, setScope] = useState(currentSimulation.scope);

  /*
    
  STEP 7: ESTABLISH INITIAL STATE OF DRAWERS (OPEN OR NOT)

  */

  // "open" determines when the input side bar is open or not
  const [open, setOpen] = useState(currentSimulation.open);

  // "adminOpen" determines when the admin side bar is open or not
  const [adminOpen, setAdminOpen] = useState(false);

  /*
    
  STEP 8: ESTABLISH INITIAL STATE OF WINDOW RESIZE TOGGLE VARIABLE

  */

  // "resizeFlipper" is a hack variable that flips between true and false everytime
  // the window resizes in order to change the props to a component so it will re-render
  const [resizeFlipper, setResizeFlipper] = useState(true);

  /*
  
  METHODS

  */

  /*

  STEP 1: DETERMINE COLOR

  */

  // this method picks the color of the input container and corresponding cards
  // based on their position.  It cycles through the five Manifest colors in order.
  function determineColor(index) {
    if (index % 5 === 0) return manifestColors.red;
    else if (index % 5 === 1) return manifestColors.blue;
    else if (index % 5 === 2) return manifestColors.green;
    else if (index % 5 === 3) return manifestColors.slate;
    else if (index % 5 === 4) return manifestColors.raspberry;
  }

  /*

  STEP 2: CREATE AND MANIPULATE INPUT CONTAINERS

  */

  // this method sets the status of a container to "active".  A number of things only
  // render when a container is active.  For instance, if a container is "active",
  // the circle icon next to the user-typed input turns from grey to the color of
  // the container and the LaTeX version of the input is rendered below it.
  function activateContainer(userInput, inputContainerIndex) {
    let tempInputContainers = [...inputContainers];
    tempInputContainers[inputContainerIndex].status = "active";
    tempInputContainers[inputContainerIndex].input = userInput;
    setInputContainers(tempInputContainers);
  }

  // this method sets the status of a container to "inactive".  See description of
  // "activateContainer" above to understand what this means
  function deactivateContainer(inputContainerIndex) {
    let tempInputContainers = [...inputContainers];
    tempInputContainers[inputContainerIndex].status = "inactive";
    setInputContainers(tempInputContainers);
  }
  // when a user has their cursor in an input container and presses the enter key,
  // this method inserts a new input container below the one they are currently in
  function insertInputContainer(keyPressed, containerIndex) {
    if (keyPressed === "Enter") {
      let newInputContainer = {
        status: "invalid",
        message: "",
        input: "",
        rawInput: "",
      };
      let newCardContainer = {
        content: {
          color: "black",
          cards: [
            {
              play: false,
              cardDisabled: true,
              valueEditable: false,
              value: 8,
              min: 0,
              max: 10,
              valueText: "8",
              minText: "0",
              maxText: "10",
              expression: "8",
              step: 0.1,
              stepText: "0.1",
              unit: "obj",
            },
          ],
        },
      };
      let tempInputContainers = [...inputContainers];
      let tempCardContainers = [...cardContainers];
      tempInputContainers.splice(containerIndex + 1, 0, newInputContainer);
      tempCardContainers.splice(containerIndex + 1, 0, newCardContainer);
      tempCardContainers.forEach((container, index) => {
        tempCardContainers[index].content.color = determineColor(index);
      });
      setInputContainers(tempInputContainers);
      setCardContainers(tempCardContainers);
    }
  }

  // when a user presses the "+" button in the inputsDrawerHeader, this method inserts
  // a new input container below all the existing ones
  function appendInputContainer() {
    let newInputContainer = {
      status: "invalid",
      message: "",
      input: "",
      rawInput: "",
    };
    let newCardContainer = {
      content: {
        color: "black",
        cards: [
          {
            play: false,
            cardDisabled: true,
            valueEditable: false,
            value: 0,
            min: -10,
            max: 10,
            valueText: "8",
            minText: "0",
            maxText: "10",
            expression: 1,
            step: 0.1,
            stepText: "0.1",
            unit: "obj",
          },
        ],
      },
    };
    let tempInputContainers = [...inputContainers];
    let tempCardContainers = [...cardContainers];
    tempInputContainers.push(newInputContainer);
    tempCardContainers.push(newCardContainer);
    setInputContainers(tempInputContainers);
    setCardContainers(tempCardContainers);
  }

  // when a user presses the "x" button within an input container, this method removes
  // that input container
  function removeInputContainer(containerIndex) {
    let tempInputContainers = [...inputContainers];
    let tempCardContainers = [...cardContainers];
    console.log("TempInputContainers", tempInputContainers);
    console.log("TempCardContainers", tempCardContainers);
    tempInputContainers.splice(containerIndex, 1);
    tempCardContainers.splice(containerIndex, 1);
    tempCardContainers.forEach((container, index) => {
      tempCardContainers[index].content.color = determineColor(index);
    });
    console.log("TempInputContainers-PostSplice", tempInputContainers);
    console.log("TempCardContainers-PostSplice", tempCardContainers);
    setInputContainers(tempInputContainers);
    setCardContainers(tempCardContainers);
  }

  /*

  STEP 3: CREATE AND MANIPULATE CARDS

  */

  // this method takes the user input and creates the cards from it.  In general, it screens
  // the user input for errors.  If there are none, it passes it to the different mathematical
  // expression screeners to see if there is a match.  If a match is found, whatever screener
  // was matched returns the relevant cards.
  function createCardsFromUserInput(
    userInput,
    inputContainerIndex,
    inputContainerColor,
    cardContainers
  ) {
    inputContainers[inputContainerIndex].rawInput = userInput;
    try {
      math.parse(userInput);
    } catch (err) {
      if (cardContainers.length > 0) {
        disableCards(inputContainerIndex);
        deactivateContainer(inputContainerIndex);
      }
      return;
    }
    let container = getContainer(
      userInput,
      inputContainerIndex,
      inputContainerColor,
      cardContainers,
      scope
    );
    let newScope = { ...scope, ...container.scope };
    setScope(newScope);

    try {
      math.eval(userInput, container.scope);
    } catch (err) {
      if (cardContainers.length > 0) {
        disableCards(inputContainerIndex);
        deactivateContainer(inputContainerIndex);
      }
      return;
    }
    if (!math.eval(userInput, container.scope)) {
      //if math.eval worked (didn't produce an error) but returned an undefined result
      disableCards(inputContainerIndex);
      deactivateContainer(inputContainerIndex);
      return;
    }

    let tempContainers = [...cardContainers];
    tempContainers[inputContainerIndex] = container;
    updateCards(tempContainers, newScope);
    activateContainer(userInput, inputContainerIndex);
  }

  // this method changes all of the cards to reflect a user change in card data,
  // such as a change in value, min, max, or step.  This is necessary since a
  // change in one card may have effects on others.  This method makes those changes.
  function updateCards(cardContainers, newScope, containerIndex, cardIndex) {
    let newValue;
    for (let i = 0; i < cardContainers.length; i++) {
      for (let j = 0; j < cardContainers[i].content.cards.length; j++) {
        if (
          i === containerIndex &&
          j === cardIndex &&
          cardContainers[i].content.cards[j].independentExpression
        ) {
          newValue = math.eval(
            cardContainers[i].content.cards[j].independentExpression,
            newScope
          );
        } else {
          newValue = math.eval(
            cardContainers[i].content.cards[j].expression,
            newScope
          );
        }
        if (cardContainers[i].content.cards[j].expression) {
          cardContainers[i].content.cards[j].value = newValue;
          cardContainers[i].content.cards[j].valueText = newValue.toString();
        }
      }

      setCardContainers(cardContainers);
    }
  }

  // this method disables all of the cards in a container.  This is done when
  // the user has typed something in the expression input field that is not
  // recognized.  It simply causes the cards not to render, but doesn't change
  // any of their stats.
  function disableCards(containerIndex) {
    let tempContainers = [...cardContainers];

    let i = 0;

    for (i = 0; i < tempContainers[containerIndex].content.cards.length; i++) {
      tempContainers[containerIndex].content.cards[i].cardDisabled = true;
      tempContainers[containerIndex].content.cards[i].play = false;
    }
    setCardContainers(tempContainers);
  }

  /*

  STEP 4: ALLOW USER TO EDIT CARD ATTRIBUTES

  */

  /*

  STEP 4a: ALLOW USER TO EDIT CARD VALUE

  */

  // this method changes the value of a card when the user moves the slider
  function changeValueSlider(containerIndex, cardIndex, value, step) {
    let tempContainers = [...cardContainers];

    value = math.bignumber(value);
    step = math.bignumber(step);

    const newValue = math
      .multiply(math.round(math.divide(value, step)), step)
      .toString();

    /* Cards that can change their own value via the slider and input, but can also
    have their value changed by variables in other cards have an "independentExpression"
    property that represents the self-changeable, single variable version.  If a card
    has this property, that variable is what cardVar is set to.*/
    let cardVar = tempContainers[containerIndex].content.cards[cardIndex]
      .independentExpression
      ? tempContainers[containerIndex].content.cards[cardIndex]
          .independentExpression
      : tempContainers[containerIndex].content.cards[cardIndex].expression;
    let newScope = { ...scope };
    newScope[cardVar] = math.bignumber(newValue);
    setScope(newScope);
    updateCards(tempContainers, newScope, containerIndex, cardIndex);
  }

  // this method updates the string version of the value when the user changes it
  // in the value input box of the card
  function updateCardValueText(containerIndex, cardIndex, userInput) {
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[
      cardIndex
    ].valueText = userInput;
    setCardContainers(tempContainers);
  }

  // this method changes the value of a card based on a user change in the value input
  // box of the card after they hit the enter key
  function changeValueInput(containerIndex, cardIndex) {
    let tempContainers = [...cardContainers];
    /* Cards that can change their own value via the slider and input, but can also
    have their value changed by variables in other cards have an "independentExpression"
    property that represents the self-changeable, single variable version.  If a card
    has this property, that variable is what cardVar is set to.*/
    let cardVar = tempContainers[containerIndex].content.cards[cardIndex]
      .independentExpression
      ? tempContainers[containerIndex].content.cards[cardIndex]
          .independentExpression
      : tempContainers[containerIndex].content.cards[cardIndex].expression;
    let newScope = { ...scope };
    try {
      newScope[cardVar] = math.bignumber(
        tempContainers[containerIndex].content.cards[cardIndex].valueText
      );
    } catch (error) {
      console.log(error);
      return;
    }
    setScope(newScope);
    updateCards(tempContainers, newScope, containerIndex, cardIndex);
  }

  // this method toggles a card's "play" value between true and false when a user
  // click the play/pause button
  function togglePlay(containerIndex, cardIndex) {
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[cardIndex].play =
      tempContainers[containerIndex].content.cards[cardIndex].play === true
        ? false
        : true;
    setCardContainers(tempContainers);
  }

  // this method animates the value of a card when the card's "play" value is true.
  function animateValue(containerIndex, cardIndex, cardStep, cardMin, cardMax) {
    cardStep = math.bignumber(cardStep);
    cardMin = math.bignumber(cardMin);
    cardMax = math.bignumber(cardMax);
    let tempContainers = [...cardContainers];
    let cardVar = tempContainers[containerIndex].content.cards[cardIndex]
      .independentExpression
      ? tempContainers[containerIndex].content.cards[cardIndex]
          .independentExpression
      : tempContainers[containerIndex].content.cards[cardIndex].expression;
    let newScope = { ...scope };
    if (
      tempContainers[containerIndex].content.cards[cardIndex].value >
      cardMax.toNumber() - cardStep.toNumber()
    ) {
      newScope[cardVar] = cardMin;
      setScope(newScope);
      updateCards(tempContainers, newScope, containerIndex, cardIndex);
      return;
    }

    newScope[cardVar] = math.add(math.eval(cardVar, newScope), cardStep);

    setScope(newScope);
    updateCards(tempContainers, newScope, containerIndex, cardIndex);
  }

  /*

  STEP 4b: ALLOW USER TO EDIT CARD MIN

  */

  // this method changes the string version of a card's min value when the user
  // changes it
  function updateCardMinText(containerIndex, cardIndex, userInput) {
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[cardIndex].minText = userInput;
    setCardContainers(tempContainers);
  }
  // this method changes the actual value of a card's min value when the user
  // presses the enter key after making changes to the card's min
  function updateCardMin(containerIndex, cardIndex) {
    let submittedMinCandidate =
      cardContainers[containerIndex].content.cards[cardIndex].minText;

    try {
      math.eval(submittedMinCandidate);
    } catch (error) {
      console.log(error);
      return;
    }
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[cardIndex].min = math
      .eval(submittedMinCandidate)
      .toNumber();
    setCardContainers(tempContainers);
  }

  /*

  STEP 4c: ALLOW USER TO EDIT CARD MAX

  */

  // this method changes the string version of a card's max value when the user
  // changes it
  function updateCardMaxText(containerIndex, cardIndex, userInput) {
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[cardIndex].maxText = userInput;
    setCardContainers(tempContainers);
  }

  // this method changes the actual value of a card's max value when the user
  // presses the enter key after making changes to the card's max
  function updateCardMax(containerIndex, cardIndex) {
    let submittedMaxCandidate =
      cardContainers[containerIndex].content.cards[cardIndex].maxText;

    try {
      math.eval(submittedMaxCandidate);
    } catch (error) {
      console.log(error);
      return;
    }
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[cardIndex].max = math
      .eval(submittedMaxCandidate)
      .toNumber();
    setCardContainers(tempContainers);
  }

  /*

  STEP 4d: ALLOW USER TO EDIT CARD STEP

  */

  // this method changes the string version of a card's step value when the user
  // changes it
  function updateCardStepText(containerIndex, cardIndex, userInput) {
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[
      cardIndex
    ].stepText = userInput;
    setCardContainers(tempContainers);
  }

  // this method changes the actual value of a card's step value when the user
  // presses the enter key after making changes to the card's step
  function updateCardStep(containerIndex, cardIndex) {
    let submittedStepCandidate =
      cardContainers[containerIndex].content.cards[cardIndex].stepText;

    try {
      math.eval(submittedStepCandidate);
    } catch (error) {
      console.log(error);
      return;
    }
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[cardIndex].step = math
      .eval(submittedStepCandidate)
      .toNumber();
    setCardContainers(tempContainers);
  }

  /*

  STEP 4e: ALLOW USER TO EDIT CARD UNITS

  */

  // this method changes the units in a card when the user selects a new one
  function changeUnits(containerIndex, cardIndex, unit) {
    let tempContainers = [...cardContainers];
    tempContainers[containerIndex].content.cards[cardIndex].unit = unit;
    setCardContainers(tempContainers);
  }

  /*

  STEP 5: ALLOW USER TO OPEN AND CLOSE MENUS

  */

  // this method opens and closes the admin drawer
  const toggleAdminDrawer = (open) => {
    setAdminOpen(open);
  };

  // this method opens the input drawer
  function handleDrawerOpen() {
    setOpen(true);
  }

  // this method closes the input drawer
  function handleDrawerClose() {
    setOpen(false);
  }

  /*

  STEP 6: HANDLE WINDOW RESIZING

  */

  // this method flips the value of resizeFlipper every time the window resizes.
  // This new value is then passed to components to propmt them to re-render.
  function handleResize() {
    setResizeFlipper(!resizeFlipper);
  }

  /*
  
  STEP 7: CONDITIONALLY RENDER COMPONENTS BASED ON WINDOW SIZE
  
  */

  // this gets the width of the window and stores it in window width
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  // this method updates the window width
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  // this method updates the window width (calls updateWindowWidth) every time the window
  // is resized
  React.useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });

  // this topBar variable is assigned the regular TopBar component if the screen is large,
  // but is assigned the TopBarMobile component if the screen is small
  const topBar =
    windowWidth > 1025 ? (
      <TopBar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        toggleAdminDrawer={toggleAdminDrawer}
        manifestColors={manifestColors}
      />
    ) : (
      <TopBarMobile
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open}
        toggleAdminDrawer={toggleAdminDrawer}
      />
    );

  // this inputsDrawer variable is assigned the regular InputsDrawer component if
  // the screen is large, but is assigned the InputsDrawerMobile component if
  // the screen is small
  const inputsDrawer =
    windowWidth > 1025 ? (
      <InputsDrawer
        handleDrawerClose={handleDrawerClose}
        open={open}
        createCardsFromUserInput={createCardsFromUserInput}
        manifestColors={manifestColors}
        inputContainers={inputContainers}
        cardContainers={cardContainers}
        disableCards={disableCards}
        appendInputContainer={appendInputContainer}
        insertInputContainer={insertInputContainer}
        removeInputContainer={removeInputContainer}
      />
    ) : (
      <InputsDrawerMobile
        handleDrawerClose={handleDrawerClose}
        open={open}
        createCardsFromUserInput={createCardsFromUserInput}
        manifestColors={manifestColors}
        inputContainers={inputContainers}
        cardContainers={cardContainers}
        disableCards={disableCards}
        appendInputContainer={appendInputContainer}
        insertInputContainer={insertInputContainer}
        removeInputContainer={removeInputContainer}
      />
    );

  // this cardsArea variable is assigned the regular CardsArea component if
  // the screen is large, but is assigned the CardsAreaMobile component if
  // the screen is small
  const cardsArea =
    windowWidth > 1025 ? (
      <CardsArea
        handleResize={handleResize}
        changeUnits={changeUnits}
        changeValueSlider={changeValueSlider}
        changeValueInput={changeValueInput}
        updateCardValueText={updateCardValueText}
        updateCardMinText={updateCardMinText}
        updateCardMaxText={updateCardMaxText}
        updateCardMax={updateCardMax}
        updateCardMin={updateCardMin}
        updateCardStepText={updateCardStepText}
        updateCardStep={updateCardStep}
        togglePlay={togglePlay}
        cardContainers={cardContainers}
        manifestColors={manifestColors}
        open={open}
        animateValue={animateValue}
      />
    ) : (
      <CardsAreaMobile
        handleResize={handleResize}
        changeUnits={changeUnits}
        changeValueSlider={changeValueSlider}
        changeValueInput={changeValueInput}
        updateCardValueText={updateCardValueText}
        updateCardMinText={updateCardMinText}
        updateCardMaxText={updateCardMaxText}
        updateCardMax={updateCardMax}
        updateCardMin={updateCardMin}
        updateCardStepText={updateCardStepText}
        updateCardStep={updateCardStep}
        togglePlay={togglePlay}
        cardContainers={cardContainers}
        manifestColors={manifestColors}
        open={open}
        animateValue={animateValue}
      />
    );

  /*
  
  STEP 8: HANDLE SIMULATION SAVING AND RETRIEVING
  
  */

  function SaveSimulation() {
    currentSimulation = {};
  }

  /*
  
  STEP 9: RENDER COMPONENTS
  
  */

  return (
    <div className={classes.root}>
      <CssBaseline />
      {topBar}
      {inputsDrawer}
      {cardsArea}
      <AdminDrawer
        adminOpen={adminOpen}
        toggleAdminDrawer={toggleAdminDrawer}
        manifestColors={manifestColors}
        currentUser={currentUser}
      />
    </div>
  );
};

export default ManifestVisualCalc;
