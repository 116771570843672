import valueToVerticalPixelMass from "./valueToVerticalPixelMass";
import * as math from "mathjs";

const drawValue = (
  ctx,
  val,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step
) => {
  let maxTextWidth = 0;

  for (let i = -2; i < 20; i++) {
    let textWidth = ctx.measureText(
      math.eval(`${firstLinePosition} + ${step} * ${i}`).toString()
    );
    if (textWidth.width > maxTextWidth) maxTextWidth = textWidth.width;
  }

  let massValue = valueToVerticalPixelMass(val, cardMin, cardMax, canvasHeight);
  let massCardMax = valueToVerticalPixelMass(
    cardMax,
    cardMin,
    cardMax,
    canvasHeight
  );
  let massCardMin = valueToVerticalPixelMass(
    cardMin,
    cardMin,
    cardMax,
    canvasHeight
  );
  let massSize = 20 * ((425.1 / 500) * canvasHeight - massValue) ** (1 / 3);

  if (val >= cardMin && val <= cardMax) {
    ctx.lineCap = "round";

    // draw background springs
    ctx.strokeStyle = "black";
    ctx.lineWidth = 6;
    ctx.beginPath();
    for (let i = 1; i < 15; i++) {
      ctx.moveTo(
        canvasWidth / 2 + 25,
        canvasHeight -
          massValue +
          (0.055 * canvasHeight * i * (massCardMax - massValue)) /
            (massCardMax - massCardMin) -
          (0.0275 * canvasHeight * (massCardMax - massValue)) /
            (massCardMax - massCardMin)
      );
      ctx.lineTo(
        canvasWidth / 2 - 25,
        canvasHeight -
          massValue +
          (0.055 * canvasHeight * i * (massCardMax - massValue)) /
            (massCardMax - massCardMin)
      );
    }
    ctx.closePath();
    ctx.stroke();

    // draw white backgrounds for foregraound springs
    ctx.strokeStyle = "white";
    ctx.lineWidth = 12;
    ctx.beginPath();
    for (let i = 0; i < 15; i++) {
      ctx.moveTo(
        canvasWidth / 2 - 25,
        canvasHeight -
          massValue +
          (0.055 * canvasHeight * i * (massCardMax - massValue)) /
            (massCardMax - massCardMin)
      );
      ctx.lineTo(
        canvasWidth / 2 + 25,
        canvasHeight -
          massValue +
          (0.055 * canvasHeight * i * (massCardMax - massValue)) /
            (massCardMax - massCardMin) +
          (0.0275 * canvasHeight * (massCardMax - massValue)) /
            (massCardMax - massCardMin)
      );
    }

    ctx.closePath();
    ctx.stroke();

    // draw foreground springs
    ctx.strokeStyle = "black";
    ctx.lineWidth = 6;
    ctx.beginPath();
    for (let i = 0; i < 15; i++) {
      ctx.moveTo(
        canvasWidth / 2 - 25,
        canvasHeight -
          massValue +
          (0.055 * canvasHeight * i * (massCardMax - massValue)) /
            (massCardMax - massCardMin)
      );
      ctx.lineTo(
        canvasWidth / 2 + 25,
        canvasHeight -
          massValue +
          (0.055 * canvasHeight * i * (massCardMax - massValue)) /
            (massCardMax - massCardMin) +
          (0.0275 * canvasHeight * (massCardMax - massValue)) /
            (massCardMax - massCardMin)
      );
    }

    ctx.closePath();
    ctx.stroke();

    //draw the mass square
    ctx.fillStyle = "lightgrey";
    ctx.fillRect(
      canvasWidth / 2 - massSize / 2,
      canvasHeight - massValue - massSize - 10,
      massSize,
      massSize
    );
    ctx.strokeStyle = "black";
    ctx.lineWidth = 6;
    ctx.strokeRect(
      canvasWidth / 2 - massSize / 2,
      canvasHeight - massValue - massSize - 10,
      massSize,
      massSize
    );

    //draw the dashed line that shows that value of the mass
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.setLineDash([5, 5]);
    ctx.beginPath();
    ctx.moveTo(0, canvasHeight - massValue);
    ctx.lineTo(canvasWidth, canvasHeight - massValue);
    ctx.closePath();
    ctx.stroke();

    //draw the line that the mass rests on
    ctx.strokeStyle = "black";
    ctx.lineWidth = 6;
    ctx.beginPath();
    ctx.moveTo(canvasWidth / 2 - 100, canvasHeight - massValue);
    ctx.lineTo(canvasWidth / 2 + 100, canvasHeight - massValue);
    ctx.closePath();
    ctx.stroke();

    //draw scale base
    ctx.strokeStyle = "black";
    ctx.lineWidth = 6;
    ctx.beginPath();
    ctx.moveTo(canvasWidth / 2 - 150, canvasHeight - massCardMax);
    ctx.lineTo(canvasWidth / 2 + 150, canvasHeight - massCardMax);
    ctx.closePath();
    ctx.stroke();
  } else {
    //draw scale base
    ctx.strokeStyle = "black";
    ctx.lineWidth = 6;
    ctx.beginPath();
    ctx.moveTo(canvasWidth / 2 - 150, canvasHeight - massCardMax);
    ctx.lineTo(canvasWidth / 2 + 150, canvasHeight - massCardMax);
    ctx.closePath();
    ctx.stroke();
  }
  ctx.strokeStyle = "grey";
  ctx.lineWidth = 1;
  ctx.setLineDash([5, 5]);

  //draw dashed line that connects the 0 line to the value line on the left
  ctx.beginPath();
  ctx.moveTo(
    maxTextWidth + 0.05 * canvasWidth,
    canvasHeight - valueToVerticalPixelMass(0, cardMin, cardMax, canvasHeight)
  );
  ctx.lineTo(maxTextWidth + 0.05 * canvasWidth, canvasHeight - massValue);
  ctx.stroke();
  ctx.closePath();

  //draw dashed line that connects the 0 line to the value line on the right
  ctx.beginPath();
  ctx.moveTo(
    canvasWidth - maxTextWidth - 0.05 * canvasWidth,
    canvasHeight - valueToVerticalPixelMass(0, cardMin, cardMax, canvasHeight)
  );
  ctx.lineTo(
    canvasWidth - maxTextWidth - 0.05 * canvasWidth,
    canvasHeight - massValue
  );
  ctx.stroke();
  ctx.closePath();
};

export default drawValue;
