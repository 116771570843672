import * as math from "mathjs";
import { getNodes } from "../getNodes";
import { autoSetRangeForOneCard } from "../CardStatAutoSettingAlgorithms/autoSetRangeForOneCard";

math.config({
  number: "BigNumber",
  precision: 64
});

export function algebraicExpression(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope,
  specialSymbols
) {
  const singleValueNumericalOutputFunctions = getNodes(expr).symbolNodes.filter(
    node => {
      return specialSymbols.singleValueNumericalOutputFunctionTypes.includes(
        node.name
      );
    }
  );

  if (
    getNodes(expr).accessorNodes.length === 0 &&
    getNodes(expr).arrayNodes.length === 0 &&
    getNodes(expr).assignmentNodes.length === 0 &&
    getNodes(expr).blockNodes.length === 0 &&
    getNodes(expr).conditionalNodes.length === 0 &&
    getNodes(expr).constantNodes.length >= 0 &&
    getNodes(expr).symbolNodes.length > 0 &&
    getNodes(expr).symbolNodes.length >= getNodes(expr).functionNodes.length &&
    getNodes(expr).symbolNodes.every(node => {
      return (
        specialSymbols.realConstants.includes(node.name) ||
        specialSymbols.singleValueNumericalOutputFunctionTypes.includes(
          node.name
        ) ||
        specialSymbols.allowableVariables.includes(node.name) ||
        /^[a-zA-Z]_[a-zA-Z0-9]$/g.test(node.name) ||
        /^Delta[a-zA-Z]$/g.test(node.name) ||
        /^Delta[a-zA-Z]_[a-zA-Z0-9]$/g.test(node.name)
      );
    }) &&
    getNodes(expr).symbolNodes.every(node => {
      return /* node.name !== "e" && */ node.name !== "i";
    }) &&
    getNodes(expr).functionNodes.length ===
      singleValueNumericalOutputFunctions.length &&
    getNodes(expr).functionalAssignmentNodes.length === 0 &&
    getNodes(expr).indexNodes.length === 0 &&
    getNodes(expr).objectNodes.length === 0 &&
    getNodes(expr).operatorNodes.length >= 0 &&
    getNodes(expr).parenthesisNodes.length >= 0 &&
    getNodes(expr).rangeNodes.length === 0 &&
    getNodes(expr).relationalNodes.length === 0
  ) {
    let varNodes = getNodes(expr).symbolNodes.filter(
      node =>
        specialSymbols.allowableVariables.includes(node.name) ||
        /^[a-zA-Z]_[a-zA-Z0-9]$/g.test(node.name) ||
        /^Delta[a-zA-Z]$/g.test(node.name) ||
        /^Delta[a-zA-Z]_[a-zA-Z0-9]$/g.test(node.name)
    );
    let scopeVars = varNodes.map(node => node.name);
    scopeVars = [...new Set(scopeVars)]; //removes duplicates by making it a set
    let independentScopeVars = [...scopeVars];
    let cardScope = {};
    let i;
    for (i = 0; i < scopeVars.length; i++) {
      cardScope[scopeVars[i]] = scope[scopeVars[i]] ? scope[scopeVars[i]] : 8;
    }

    try {
      math.eval(expr, cardScope);
    } catch (error) {
      console.log(error);
      return false;
    }

    let variableCards = independentScopeVars.map((variable, index) => ({
      play: false,
      cardDisabled: false,
      valueEditable: true,
      value: cardScope[variable],
      min: cardContainers[inputContainerIndex].content.cards[index]
        ? cardContainers[inputContainerIndex].content.cards[index].min
        : autoSetRangeForOneCard(cardScope[variable]).min,
      max: cardContainers[inputContainerIndex].content.cards[index]
        ? cardContainers[inputContainerIndex].content.cards[index].max
        : autoSetRangeForOneCard(cardScope[variable]).max,
      minText: cardContainers[inputContainerIndex].content.cards[index]
        ? cardContainers[inputContainerIndex].content.cards[
            index
          ].min.toString()
        : autoSetRangeForOneCard(cardScope[variable]).min.toString(),
      maxText: cardContainers[inputContainerIndex].content.cards[index]
        ? cardContainers[inputContainerIndex].content.cards[
            index
          ].max.toString()
        : autoSetRangeForOneCard(cardScope[variable]).max.toString(),
      expression: variable,
      step: cardContainers[inputContainerIndex].content.cards[index]
        ? cardContainers[inputContainerIndex].content.cards[index].step
        : 0.1,
      stepText: cardContainers[inputContainerIndex].content.cards[index]
        ? cardContainers[inputContainerIndex].content.cards[index].stepText
        : "0.1",
      unit: cardContainers[inputContainerIndex].content.cards[index]
        ? cardContainers[inputContainerIndex].content.cards[index].unit
        : "obj"
    }));

    if (cardContainers[inputContainerIndex]) {
      return {
        content: {
          color: inputContainerColor,
          cards: [
            ...variableCards,
            {
              play: false,
              cardDisabled: false,
              valueEditable: false,
              value: math.eval(expr, cardScope),
              min: cardContainers[inputContainerIndex].content.cards[
                independentScopeVars.length
              ]
                ? cardContainers[inputContainerIndex].content.cards[
                    independentScopeVars.length
                  ].min
                : autoSetRangeForOneCard(math.eval(expr, cardScope))
                ? autoSetRangeForOneCard(math.eval(expr, cardScope)).min
                : 0,
              max: cardContainers[inputContainerIndex].content.cards[
                independentScopeVars.length
              ]
                ? cardContainers[inputContainerIndex].content.cards[
                    independentScopeVars.length
                  ].max
                : autoSetRangeForOneCard(math.eval(expr, cardScope))
                ? autoSetRangeForOneCard(math.eval(expr, cardScope)).max
                : 0,
              minText: cardContainers[inputContainerIndex].content.cards[
                independentScopeVars.length
              ]
                ? cardContainers[inputContainerIndex].content.cards[
                    independentScopeVars.length
                  ].min.toString()
                : autoSetRangeForOneCard(math.eval(expr, cardScope))
                ? autoSetRangeForOneCard(
                    math.eval(expr, cardScope)
                  ).min.toString()
                : "0",
              maxText: cardContainers[inputContainerIndex].content.cards[
                independentScopeVars.length
              ]
                ? cardContainers[inputContainerIndex].content.cards[
                    independentScopeVars.length
                  ].max.toString()
                : autoSetRangeForOneCard(math.eval(expr, cardScope))
                ? autoSetRangeForOneCard(
                    math.eval(expr, cardScope)
                  ).max.toString()
                : "0",
              expression: expr,
              step: 0.1,
              stepText: "0.1",
              unit: cardContainers[inputContainerIndex].content.cards[
                independentScopeVars.length
              ]
                ? cardContainers[inputContainerIndex].content.cards[
                    independentScopeVars.length
                  ].unit
                : "obj"
            }
          ]
        },
        scope: cardScope
      };
    } else return false;
  }
}
