import React from "react";
import CardCanvas from "./cardCanvas/cardCanvas";
import CardSlider from "./cardSlider";
import CardBottom from "./cardBottom";
import Grid from "@material-ui/core/Grid";
import * as math from "mathjs";
import MathJax from "react-mathjax2";

const Card = ({
  color,
  card,
  changeUnits,
  cardIndex,
  containerIndex,
  changeValueSlider,
  changeValueInput,
  updateCardValueText,
  updateCardMinText,
  updateCardMaxText,
  updateCardMax,
  updateCardMin,
  updateCardStepText,
  updateCardStep,
  togglePlay,
  handleResize,
  animateValue
}) => {
  let cardDisabled = "";
  /*
  The code below creates the LaTex label for the card. If a card's label is
  different than the actual expression used to calculate the card's value, the
  card has a property called "expressionText" that is used to denote the desired
  string that should be turned into the LaTex label for that card.
  */
  let cardMathLabel = card.expressionText
    ? math
        .parse(card.expressionText)
        .toTex()
        .replace(/~|:/g, "")
        .replace(/\\_/g, "_")
        .replace(/[^\\]Delta/g, "\\Delta ")
    : math
        .parse(card.expression)
        .toTex()
        .replace(/~|:/g, "")
        .replace(/\\_/g, "_")
        .replace(/[^\\]Delta/g, "\\Delta ");

  console.log("LABEL", cardMathLabel);

  if (card.cardDisabled === true) cardDisabled = "none";
  return (
    <Grid
      item
      sm={12}
      md={6}
      xl={4}
      style={{ display: cardDisabled, marginBottom: "-18px" }}
    >
      <MathJax.Context input="tex">
        <div
          style={{
            backgroundColor: color,
            marginBottom: "-20px",
            borderRadius: "10px",
            minWidth: "100px",
            color: "white",
            display: "inline-block",
            paddingLeft: "20px",
            paddingRight: "35px",
            paddingBottom: "2px",
            borderTopRightRadius: "50px",
            textAlign: "center",
            fontSize: "14px"
          }}
        >
          <div style={{ marginTop: "-5px", paddingBottom: "10px" }}>
            <MathJax.Node style={{ marginTop: "30px" }}>
              {cardMathLabel}
            </MathJax.Node>
          </div>
        </div>
      </MathJax.Context>
      <CardCanvas
        handleResize={handleResize}
        cardMin={card.min}
        cardMax={card.max}
        cardValue={card.value}
        cardUnit={card.unit}
        color={color}
      />

      <CardSlider
        card={card}
        cardIndex={cardIndex}
        containerIndex={containerIndex}
        changeValueSlider={changeValueSlider}
      />

      <CardBottom
        card={card}
        cardIndex={cardIndex}
        containerIndex={containerIndex}
        changeUnits={changeUnits}
        changeValueInput={changeValueInput}
        updateCardValueText={updateCardValueText}
        updateCardMinText={updateCardMinText}
        updateCardMaxText={updateCardMaxText}
        updateCardMax={updateCardMax}
        updateCardMin={updateCardMin}
        updateCardStepText={updateCardStepText}
        updateCardStep={updateCardStep}
        togglePlay={togglePlay}
        color={color}
        animateValue={animateValue}
      />
    </Grid>
  );
};

export default Card;
