import { databaseUsers } from "./databaseUsers";
import { databaseSimulations } from "./databaseSimulations";

export function getUserSimulations(userName) {
  const users = databaseUsers();
  console.log(users);

  const simulations = databaseSimulations();
  console.log(simulations);
  const user = users.filter(user => user.userName === userName)[0];
  console.log(user);
  const userSimulations = simulations.filter(simulation =>
    user.simulations.includes(simulation.simulationID)
  );
  return userSimulations;
}
