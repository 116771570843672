import valueToTwoAxesVerticalPixel from "../valueToTwoAxesVerticalPixel";
import * as math from "mathjs";
import determineStep from "../determineStep";
import determineFirstLinePosition from "../determineFirstLinePosition";

math.config({
  number: "BigNumber",
  precision: 64,
});

const drawIntervalLines = (
  ctx,
  originalCardMin,
  originalCardMax,
  canvasWidth,
  canvasHeight,
  originalCardValue,
  cardColor
) => {
  let rectangleWidth;
  let rectangleHeight;
  let numColumns;
  let columnWidth;
  let rowHeight;
  let rectangleLeftBorder;
  let rectangleRightBorder;
  let rectangleTopBorder;
  let rectangleBottomBorder;
  let renderAreaWidth;
  let numberOfVisibleColumns;
  let thinWhiteBlockingRectangleHeight;
  let whiteRectNumColumns;
  const intervalNumberSidePadding = 50; //padding space between interval numbers and render area
  const renderAreaPadding = 20; //padding within the render area for the black rectangle that makes up the number of objects
  ctx.font = "24px Montserrat"; // set the font size correctly so the width is measured true to display
  let maxTextWidth = ctx.measureText("1000").width; //default maxTextWidth value is the width of '1000', which is the biggest width possible when cardMax - cardMin > 10

  try {
    originalCardValue = math.bignumber(originalCardValue);
    originalCardMin = math.bignumber(originalCardMin);
    originalCardMax = math.bignumber(originalCardMax);
  } catch (error) {
    console.log(error);
    originalCardValue = math.bignumber(0);
    originalCardMin = math.bignumber(0);
    originalCardMax = math.bignumber(0);
  }

  //this section of code resets (scales) the cardValue, cardMin, and cardMax to be between
  //0 and 1000 no matter what actual values are.  This is so they can be represented
  //accurately using 1000 objects.
  let powerOf1000 = 0;
  if (originalCardMax - originalCardMin !== 0) {
    powerOf1000 = math.floor(
      math.divide(
        math.log10(math.subtract(originalCardMax, originalCardMin)),
        3
      )
    );
  }
  powerOf1000 =
    math.divide(
      math.log10(math.subtract(originalCardMax, originalCardMin)),
      3
    ) %
      1 ===
    0
      ? math.subtract(powerOf1000, 1)
      : powerOf1000;
  let scaleFactor = math.pow(1000, powerOf1000);
  let decimalModifier = 1;
  try {
    if (powerOf1000 <= 0) {
      decimalModifier = math.bignumber(math.pow(1000, powerOf1000));
    }
  } catch (error) {
    decimalModifier = 1;
    console.log(error);
  }
  //the scaleFactor adjustment below is for the border cases when cardmax - cardMin is exactly
  //a power of 1000.  In those cases, we want to represent the number of objects in that
  //range with 1000 objects, not one group that represents 1000.  This adjusts that case.
  let cardValue;
  let cardMin;
  let cardMax;
  try {
    cardValue = math.divide(originalCardValue, scaleFactor).toNumber();
    cardMin = math.divide(originalCardMin, scaleFactor).toNumber();
    cardMax = math.divide(originalCardMax, scaleFactor).toNumber();
  } catch (error) {
    cardValue = 0;
    cardMin = 0;
    cardMax = 0;
    console.log(error);
  }
  //get the step and the firstLinePosition
  let minMaxRange =
    math.subtract(cardMax, cardMin) >= 0 ? math.subtract(cardMax, cardMin) : 0;
  minMaxRange = math.bignumber(minMaxRange);

  let step = determineStep(minMaxRange);
  let firstLinePosition = determineFirstLinePosition(
    step,
    cardMin,
    minMaxRange,
    70
  );

  //get the integers in between the cardMin and cardMax if cardMax - cardMin <= 10
  let integersInRange = [];
  if (math.subtract(cardMax, cardMin) <= 10) {
    for (let i = math.ceil(cardMin); i <= math.floor(cardMax); i++) {
      integersInRange.push(i);
    }
  }

  //get the multiples of 10 in between the cardMin and cardMax if cardMax - cardMin <= 100
  let multiplesOf10InRange = [];
  if (math.subtract(cardMax, cardMin) <= 100) {
    for (
      let i = math.ceil(math.divide(cardMin, 10));
      i <= math.floor(math.divide(cardMax, 10));
      i++
    ) {
      multiplesOf10InRange.push(math.multiply(10, i));
    }
  }

  if (cardMin % 10 !== 0 && multiplesOf10InRange.length > 0) {
    //adds a multiple of 10 below cardMin if cardMin is very close to (less than 10 away from) a multiple of 10
    let lowestMultipleOf10 = math.multiply(
      math.ceil(math.divide(cardMin, 10)),
      10
    );
    multiplesOf10InRange.splice(0, 0, math.subtract(lowestMultipleOf10, 10));
  }

  if (cardMax % 10 !== 0 && multiplesOf10InRange.length > 0) {
    //adds a multiple of 10 above cardMax if cardMax is very close to (less than 10 away from) a multiple of 10
    multiplesOf10InRange.push(
      math.add(
        multiplesOf10InRange[math.subtract(multiplesOf10InRange.length, 1)],
        10
      )
    );
  }

  //get the multiples of 100 in between the cardMin and cardMax
  let multiplesOf100InRange = [];
  if (cardMax - cardMin > 100) {
    for (
      let i = math.ceil(math.divide(cardMin, 100));
      i <= math.floor(math.divide(cardMax, 100));
      i++
    ) {
      multiplesOf100InRange.push(math.multiply(100, i));
    }

    if (
      math.divide(cardMin % 100, 10) < 1 &&
      math.divide(cardMin % 100, 10) > 0 &&
      multiplesOf100InRange.length > 0
    ) {
      //adds a multiple of 100 below cardMin if cardMin is very close to (less than 10 away from) a multiple of 100
      let lowestMultipleOf100 = math.multiply(
        math.ceil(math.divide(cardMin, 100)),
        100
      );
      multiplesOf100InRange.splice(
        0,
        0,
        math.subtract(lowestMultipleOf100, 100)
      );
    }

    if (
      math.divide(cardMax % 100, 10) > 9 &&
      math.divide(cardMax % 100, 10) < 10 &&
      multiplesOf100InRange.length > 0
    ) {
      //adds a multiple of 100 above cardMax if cardMax is very close to (less than 10 away from) a multiple of 100
      multiplesOf100InRange.push(
        math.add(
          multiplesOf100InRange[math.subtract(multiplesOf100InRange.length, 1)],
          100
        )
      );
    }
  }

  // ----pixel values used for drawing the value bar and white coverage rectangle----
  let pixelMin = valueToTwoAxesVerticalPixel(
    cardMin,
    cardMin,
    cardMax,
    canvasHeight
  );
  let pixelMax = valueToTwoAxesVerticalPixel(
    cardMax,
    cardMin,
    cardMax,
    canvasHeight
  );
  let pixelVal = valueToTwoAxesVerticalPixel(
    cardValue,
    cardMin,
    cardMax,
    canvasHeight
  );

  // ----vertical interval values----
  let thousandsDigitOfValue = math.floor((cardValue % 10000) / 1000);
  let hundredsDigitOfValue = math.floor((cardValue % 1000) / 100);
  let tensDigitOfValue = math.floor((cardValue % 100) / 10);
  let onesDigitPlusDecimalOfValue = cardValue % 10;
  let rangeBiggerThanTenVerticalIntervalMin =
    cardValue % 10 === 0
      ? 1000 * thousandsDigitOfValue +
        100 * hundredsDigitOfValue +
        10 * tensDigitOfValue -
        10
      : 100 * hundredsDigitOfValue + 10 * tensDigitOfValue;
  let verticalIntervalMin =
    cardMax - cardMin > 10 && cardValue !== 0
      ? rangeBiggerThanTenVerticalIntervalMin
      : cardMin;
  let verticalIntervalMax =
    cardMax - cardMin > 10 ? verticalIntervalMin + 10 : cardMax;
  let verticalIntervalStep =
    cardMax - cardMin > 10
      ? (valueToTwoAxesVerticalPixel(
          verticalIntervalMax,
          verticalIntervalMax,
          verticalIntervalMin,
          canvasHeight
        ) -
          valueToTwoAxesVerticalPixel(
            verticalIntervalMin,
            verticalIntervalMax,
            verticalIntervalMin,
            canvasHeight
          )) /
        10
      : step;
  let pixelVerticalIntervalMax = valueToTwoAxesVerticalPixel(
    verticalIntervalMax,
    verticalIntervalMin,
    verticalIntervalMax,
    canvasHeight
  );
  // ----end vertical interval values----

  /*
  BEGIN DRAWING THE SIMULATION
  */

  // ----only draw the simulation if the cardValue is between cardMin and cardMax----
  if (cardValue >= cardMin && cardValue <= cardMax) {
    // ----begin handling the case where both the max and min are positive----
    //if (cardMin >= 0 && cardMax >= 0) {
    if (1) {
      /*
      ESTABLISH IMPORTANT VARIABLES
      */

      // ----maxTextWidth----
      // maxTextWidth is initially set to the width of '1000' at 24px Montserrat
      // ----begin - if a custom interval value is bigger than '1000', increase the maxWidth----
      if (cardMax - cardMin <= 10) {
        for (let i = 0; i < 20; i++) {
          let currentIntervalValue = math.eval(
            `${firstLinePosition} + ${step} * ${i}`
          );
          // the if statement below filters out values outside of the min and max
          if (
            currentIntervalValue >= cardMin &&
            currentIntervalValue <= cardMax
          ) {
            //get text width
            let textWidth = ctx.measureText(
              math
                .multiply(
                  math.add(firstLinePosition, math.multiply(step, i)),
                  decimalModifier
                )
                .toString()
            );
            // replace maxTextWidth with textWidth if textWidth is bigger
            if (textWidth.width > maxTextWidth) {
              maxTextWidth = textWidth.width;
            }
          }
        }
      }
      // ----end - if a custom interval value is bigger than '1000', increase the maxWidth----
      // ----end maxTextWidth----

      // ----column values----
      numColumns = math.ceil(cardMax / 10) - math.floor(cardMin / 10);
      renderAreaWidth =
        canvasWidth -
        2 * maxTextWidth -
        2 * intervalNumberSidePadding -
        2 * renderAreaPadding;
      columnWidth =
        numColumns <= 10 ? renderAreaWidth / 10 : renderAreaWidth / numColumns;
      // ----end column values----

      // ----black rectangle values----
      rectangleWidth = columnWidth * numColumns;
      rectangleHeight =
        valueToTwoAxesVerticalPixel(
          verticalIntervalMax,
          verticalIntervalMin,
          verticalIntervalMax,
          canvasHeight
        ) -
        valueToTwoAxesVerticalPixel(
          verticalIntervalMin,
          verticalIntervalMin,
          verticalIntervalMax,
          canvasHeight
        );
      rowHeight = rectangleHeight / 10;
      rectangleLeftBorder = canvasWidth / 2 - rectangleWidth / 2;
      rectangleRightBorder = canvasWidth / 2 + rectangleWidth / 2;
      rectangleBottomBorder = valueToTwoAxesVerticalPixel(
        verticalIntervalMax,
        verticalIntervalMin,
        verticalIntervalMax,
        canvasHeight
      );
      rectangleTopBorder = valueToTwoAxesVerticalPixel(
        verticalIntervalMin,
        verticalIntervalMin,
        verticalIntervalMax,
        canvasHeight
      );
      // ----end rectangle values----

      /*
      DRAW INTERVAL LINES AND NUMBERS
      */

      //----begin drawing interval lines and text if cardmax - cardmin <= 10----
      if (cardMax - cardMin <= 10) {
        // ----begin drawing the interval lines and numbers----
        for (let i = 0; i < 20; i++) {
          // It goes through 20 just to be safe since the maximum number of lines drawn will always be less than 20.
          let currentIntervalPixel = valueToTwoAxesVerticalPixel(
            firstLinePosition + step * i,
            cardMin,
            cardMax,
            canvasHeight
          );

          let currentIntervalValue = math.eval(
            `${firstLinePosition} + ${step} * ${i}`
          );

          let textWidth = ctx.measureText(
            math
              .multiply(
                math.add(firstLinePosition, math.multiply(step, i)),
                decimalModifier
              )
              .toString()
          );
          // ----begin filtering out values that are outside the card range.  Only process values that go from cardMin to cardMax.----
          if (
            currentIntervalValue >= cardMin &&
            currentIntervalValue <= cardMax
          ) {
            // ----begin drawing horizontal interval lines----
            ctx.lineWidth = 1;
            ctx.strokeStyle = "grey";
            ctx.beginPath();
            ctx.moveTo(0, currentIntervalPixel);
            ctx.lineTo(canvasWidth, currentIntervalPixel);
            ctx.closePath();
            ctx.stroke();
            // ----end drawing horizontal interval lines----

            // ----begin creating white background rectangles for text----
            ctx.fillStyle = "white";

            // draw left hand side rectangles
            ctx.fillRect(
              0,
              currentIntervalPixel - 10,
              textWidth.width + 22,
              20
            );

            // draw right hand side rectangles
            ctx.fillRect(
              canvasWidth - textWidth.width - 22,
              currentIntervalPixel - 10,
              textWidth.width + 22,
              20
            );
            // ----end creating white background rectangles for text----

            // ----begin creating text----
            //format text
            ctx.font = "24px Montserrat";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "grey";

            //format text for left hand side
            ctx.textAlign = "start";

            //draw left hand side text
            ctx.fillText(
              math.multiply(
                math.add(firstLinePosition, math.multiply(step, i)),
                decimalModifier
              ),
              //math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
              10,
              currentIntervalPixel
            );

            // format text for right hand side
            ctx.textAlign = "end";

            //draw right hand side text
            ctx.fillText(
              math.multiply(
                math.add(firstLinePosition, math.multiply(step, i)),
                decimalModifier
              ),
              //math.eval(`${firstLinePosition} + ${step} * ${i}`).toString(),
              canvasWidth - 10,
              currentIntervalPixel
            );
            // ----end creating text----
          } // if (currentIntervalValue >= cardMin && currentIntervalValue <= cardMax)
          // ----end filtering out values that are outside the card range----
        } // for (let i = 0; i < 20; i++)
        // ----end drawing the interval lines and numbers----
      } // if (cardMax - cardMin <= 10)
      //----end drawing interval lines and text if cardmax - cardmin <= 10----

      // ---- begin drawing interval lines and text if cardmax - cardmin > 10----
      if (cardMax - cardMin > 10) {
        // ----begin drawing the interval lines and numbers----
        for (let i = 0; i <= 10; i++) {
          let currentIntervalPixel = valueToTwoAxesVerticalPixel(
            verticalIntervalMin + i,
            verticalIntervalMin,
            verticalIntervalMax,
            canvasHeight
          );

          //get text width
          let textWidth = ctx.measureText(
            math
              .multiply(math.add(verticalIntervalMin, i), decimalModifier)
              .toString()
          );

          // ----begin drawing horizontal interval lines----
          ctx.lineWidth = 1;
          ctx.strokeStyle = "grey";
          ctx.beginPath();
          ctx.moveTo(0, currentIntervalPixel);
          ctx.lineTo(canvasWidth, currentIntervalPixel);
          ctx.closePath();
          ctx.stroke();
          // ----end drawing horizontal interval lines----

          // ----begin creating white background rectangles for text----
          ctx.fillStyle = "white";

          // draw left hand side rectangles
          ctx.fillRect(0, currentIntervalPixel - 10, textWidth.width + 22, 20);

          // draw right hand side rectangles
          ctx.fillRect(
            canvasWidth - textWidth.width - 22,
            currentIntervalPixel - 10,
            textWidth.width + 22,
            20
          );
          // ----end creating white background rectangles for text----

          // ----begin creating text----
          //format text
          ctx.font = "24px Montserrat";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "grey";

          //format text for left hand side
          ctx.textAlign = "start";

          //draw left hand side text
          ctx.fillText(
            math.multiply(math.add(verticalIntervalMin, i), decimalModifier),
            //math.eval(`${verticalIntervalMin} + ${i}`).toString(),
            10,
            currentIntervalPixel
          );

          // format text for right hand side
          ctx.textAlign = "end";

          //draw right hand side text
          ctx.fillText(
            math.multiply(math.add(verticalIntervalMin, i), decimalModifier),
            //math.eval(`${verticalIntervalMin} + ${i}`).toString(),
            canvasWidth - 10,
            currentIntervalPixel
          );
          // ----end creating text----
        } // for (let i = 0; i <= 10; i++)
        // ----begin drawing white rectangle that blocks left hand text when numColumns > 1----
        if (numColumns > 1) {
          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, canvasWidth / 2, canvasHeight);
        }
        // ----end drawing white rectangle that blocks left hand text when numColumns > 1----
        // ---- end drawing interval lines and text----
      } //if (cardMax - cardMin > 10) {
      // ---- end drawing interval lines and text if cardmax - cardmin > 10----

      /*
      BEGIN DRAWING THE VALUE RENDER AREA AND VALUE
      */

      // ----begin drawing big white background rectangle----
      ctx.fillStyle = "white";
      ctx.fillRect(
        maxTextWidth + intervalNumberSidePadding,
        valueToTwoAxesVerticalPixel(
          verticalIntervalMin,
          verticalIntervalMin,
          verticalIntervalMax,
          canvasHeight
        ) + renderAreaPadding,
        canvasWidth - 2 * maxTextWidth - 2 * intervalNumberSidePadding,
        valueToTwoAxesVerticalPixel(
          verticalIntervalMax,
          verticalIntervalMin,
          verticalIntervalMax,
          canvasHeight
        ) -
          valueToTwoAxesVerticalPixel(
            verticalIntervalMin,
            verticalIntervalMin,
            verticalIntervalMax,
            canvasHeight
          ) -
          2 * renderAreaPadding
      );
      // ----end drawing big background white rectangle----

      // ----begin drawing big white background rectangle outline----
      ctx.strokeStyle = "grey";
      ctx.lineWidth = 2;
      ctx.strokeRect(
        maxTextWidth + intervalNumberSidePadding,
        valueToTwoAxesVerticalPixel(
          verticalIntervalMin,
          verticalIntervalMin,
          verticalIntervalMax,
          canvasHeight
        ) + renderAreaPadding,
        canvasWidth - 2 * maxTextWidth - 2 * intervalNumberSidePadding,
        valueToTwoAxesVerticalPixel(
          verticalIntervalMax,
          verticalIntervalMin,
          verticalIntervalMax,
          canvasHeight
        ) -
          valueToTwoAxesVerticalPixel(
            verticalIntervalMin,
            verticalIntervalMin,
            verticalIntervalMax,
            canvasHeight
          ) -
          2 * renderAreaPadding
      );
      // ----end drawing big white background rectangle outline----

      // ----begin drawing black rectangle----
      ctx.fillStyle = "black";
      ctx.fillRect(
        rectangleLeftBorder,
        rectangleBottomBorder,
        rectangleWidth,
        -rectangleHeight
      );
      // ----end drawing black rectangle----

      // ----begin drawing vertical white lines----
      ctx.strokeStyle = "white";
      ctx.lineWidth = 3;
      for (let i = 0; i <= numColumns; i++) {
        ctx.beginPath();
        ctx.moveTo(
          rectangleLeftBorder + columnWidth * i,
          rectangleBottomBorder
        );
        ctx.lineTo(rectangleLeftBorder + columnWidth * i, rectangleTopBorder);
        ctx.closePath();
        ctx.stroke();
      }
      // ----end drawing vertical white lines----

      // ----begin draw horizontal white lines----
      // ----begin draw horizontal white lines when cardMax - cardMin <= 10----
      if (cardMax - cardMin <= 10) {
        integersInRange.forEach((element) => {
          ctx.lineWidth = 3;
          ctx.strokeStyle = "white";
          ctx.beginPath();
          ctx.moveTo(
            rectangleLeftBorder,
            valueToTwoAxesVerticalPixel(element, cardMin, cardMax, canvasHeight)
          );
          ctx.lineTo(
            rectangleRightBorder,
            valueToTwoAxesVerticalPixel(element, cardMin, cardMax, canvasHeight)
          );
          ctx.closePath();
          ctx.stroke();
        });
      } //if (cardMax - cardMin <= 10)
      // ----end draw horizontal white lines when cardMax - cardMin <= 10----
      // ---begin drawing horizontal white lines when cardMin - cardMax > 10---
      if (cardMax - cardMin > 10) {
        for (let i = 0; i <= 10; i++) {
          ctx.lineWidth = 3;
          ctx.strokeStyle = "white";
          ctx.beginPath();
          ctx.moveTo(
            rectangleLeftBorder,
            rectangleBottomBorder + i * verticalIntervalStep
          );
          ctx.lineTo(
            rectangleRightBorder,
            rectangleBottomBorder + i * verticalIntervalStep
          );
          ctx.closePath();
          ctx.stroke();
        } //for (let i = 0; i <= 10; i++)
      } //if (cardMax - cardMin > 10)
      // ---end drawing horizontal white lines when cardMin - cardMax > 10---
      // ----end drawing horizontal white lines----
      // ----begin drawing white rectangles that block the values that shouldn't be visible----
      if (numColumns > 1) {
        // ----begin drawing large white rectangle that blocks groups of ten when cardMin - cardMax > 10----
        whiteRectNumColumns =
          math.ceil(cardMax / 10) - math.ceil(cardValue / 10);
        ctx.fillStyle = "rgba(255, 255, 255, 0.95)";
        ctx.fillRect(
          rectangleRightBorder,
          pixelVerticalIntervalMax,
          -whiteRectNumColumns * columnWidth,
          -rectangleHeight
        );
        // ----end drawing large white rectangle that blocks groups of ten when cardMin - cardMax > 10----
        // ----begin drawing small white rectangle that blocks groups of one when cardMin - cardMax > 10----
        numberOfVisibleColumns =
          math.floor(cardValue / 10) - math.floor(cardMin / 10);
        thinWhiteBlockingRectangleHeight =
          onesDigitPlusDecimalOfValue === 0
            ? 0
            : -(10 - onesDigitPlusDecimalOfValue) * rowHeight;
        ctx.fillStyle = "rgba(255, 255, 255, 0.95)";
        ctx.fillRect(
          rectangleLeftBorder + numberOfVisibleColumns * columnWidth,
          pixelVerticalIntervalMax,
          columnWidth,
          thinWhiteBlockingRectangleHeight
        );

        // ----end drawing small white rectangle that blocks groups of one when cardMin - cardMax > 10----
      } // if (cardMax - cardMin > 10)
      // ----begin drawing white rectangle that blocks groups of ten when cardMin - cardMax <= 10----
      if (numColumns === 1) {
        ctx.fillStyle = "rgb(255,255,255,0.95)";
        ctx.fillRect(
          intervalNumberSidePadding + maxTextWidth + renderAreaPadding,
          valueToTwoAxesVerticalPixel(cardMax, cardMin, cardMax, canvasHeight),
          canvasWidth -
            2 * (intervalNumberSidePadding + maxTextWidth + renderAreaPadding),
          -((pixelMax - pixelMin) / (pixelMin - pixelMax)) *
            (pixelVal - pixelMax)
        );
      } // if (cardMax - cardMin <= 10)
      // ----end drawing white rectangle that blocks groups of ten when cardMin - cardMax <= 10----
      // ----end drawing white rectangles that block the values that shouldn't be visible----

      // ----begin drawing dashed value line----
      // ----begin drawing dashed value line when cardMax - cardMin <= 10----
      if (numColumns === 1) {
        ctx.strokeStyle = "grey";
        ctx.lineWidth = 1;
        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(maxTextWidth + intervalNumberSidePadding, pixelVal);
        ctx.lineTo(
          canvasWidth - maxTextWidth - intervalNumberSidePadding,
          pixelVal
        );
        ctx.closePath();
        ctx.stroke();
        ctx.setLineDash([]);
      }
      // ----end drawing dashed value line when cardMax - cardMin <= 10----
      // ----begin drawing dashed value line when cardMax - cardMin > 10----
      if (numColumns > 1) {
        let leftEdgeOfValueLine =
          onesDigitPlusDecimalOfValue === 0
            ? numberOfVisibleColumns * columnWidth
            : (numberOfVisibleColumns + 1) * columnWidth;
        ctx.strokeStyle = "grey";
        ctx.lineWidth = 1;
        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(
          rectangleLeftBorder + leftEdgeOfValueLine,
          rectangleBottomBorder + thinWhiteBlockingRectangleHeight
        );
        ctx.lineTo(
          canvasWidth - maxTextWidth - intervalNumberSidePadding,
          rectangleBottomBorder + thinWhiteBlockingRectangleHeight
        );
        ctx.closePath();
        ctx.stroke();
        ctx.setLineDash([]);
      } // if (cardMax - cardMin > 10)
      // ----end drawing dashed value line when cardMax - cardMin > 10----
      // ----end drawing dashed value line----
      // ----begin drawing vertical lines at multiples of 10 and 100----
      if (numColumns > 1) {
        multiplesOf100InRange.forEach((multiple) => {
          ctx.strokeStyle = cardColor;
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(
            rectangleLeftBorder +
              (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
            rectangleBottomBorder - renderAreaPadding
          );
          ctx.lineTo(
            rectangleLeftBorder +
              (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
            rectangleTopBorder + renderAreaPadding
          );
          ctx.closePath();
          ctx.stroke();
        });
        multiplesOf10InRange.forEach((multiple) => {
          ctx.strokeStyle = cardColor;
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.moveTo(
            rectangleLeftBorder +
              (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
            rectangleBottomBorder - renderAreaPadding
          );
          ctx.lineTo(
            rectangleLeftBorder +
              (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
            rectangleTopBorder + renderAreaPadding
          );
          ctx.closePath();
          ctx.stroke();
        });
      }
      // ----end drawing vertical lines at multiples of 10 and 100----
      // ----begin drawing text and tick lines at multiples of 10 and 100----
      if (numColumns > 1) {
        multiplesOf100InRange.forEach((multiple, index) => {
          ctx.fillStyle = "grey";
          ctx.textBaseline = "middle";
          ctx.textAlign = "center";

          let everyOtherElementStaggerBoolean = index % 2;

          if (everyOtherElementStaggerBoolean === 1) {
            ctx.strokeStyle = "grey";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(
              rectangleLeftBorder +
                (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
              rectangleTopBorder + renderAreaPadding
            );
            ctx.lineTo(
              rectangleLeftBorder +
                (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
              rectangleTopBorder + 1.5 * renderAreaPadding
            );
            ctx.closePath();
            ctx.stroke();
          }

          ctx.fillText(
            //multiple,
            math.multiply(multiple, decimalModifier),
            rectangleLeftBorder +
              (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
            rectangleTopBorder +
              (2 + everyOtherElementStaggerBoolean / 3) * renderAreaPadding
          );
        });

        multiplesOf10InRange.forEach((multiple, index) => {
          ctx.fillStyle = "grey";
          ctx.textBaseline = "middle";
          ctx.textAlign = "center";

          let everyOtherElementStaggerBoolean = index % 2;

          if (everyOtherElementStaggerBoolean === 1) {
            ctx.strokeStyle = "grey";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(
              rectangleLeftBorder +
                (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
              rectangleTopBorder + renderAreaPadding
            );
            ctx.lineTo(
              rectangleLeftBorder +
                (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
              rectangleTopBorder + 1.5 * renderAreaPadding
            );
            ctx.closePath();
            ctx.stroke();
          }

          ctx.fillText(
            math.multiply(multiple, decimalModifier),
            //multiple,
            rectangleLeftBorder +
              (multiple / 10 - math.floor(cardMin / 10)) * columnWidth,
            rectangleTopBorder +
              (2 + everyOtherElementStaggerBoolean / 3) * renderAreaPadding
          );
        });
      }
      // ----end drawing text at multiples of 100----
      // ----begin drawing single black rectangle scale----
      ctx.fillStyle = "black";
      ctx.fillRect(
        0.025 * canvasHeight,
        0.035 * canvasHeight,
        0.05 * canvasHeight,
        0.05 * canvasHeight
      );

      ctx.fillStyle = "grey";
      ctx.textAlign = "start";
      ctx.textBaseline = "top";
      ctx.fillText(`= ${scaleFactor}`, 0.1 * canvasHeight, 0.04 * canvasHeight);
      // ----end drawing single black rectangle scale----
    } // if (cardMin >=0 && cardMax <=0)
    // ----end the case where both the min and max are positive----

    // ---begin the case where both the min is negative and max is positive---
    if (cardMin < 0 && cardMax > 0) {
    } //if (cardMin < 0 && cardMax > 0)
    // ---end the case where both the min is negative and max is positive---
    // ---begin the case where both the min and max are negative---
    if (cardMin <= 0 && cardMax <= 0) {
    } // if (cardMin >=0 && cardMax <=0)
    // ----end the case where both the min and max are negative----
  }
  // ----if the cardValue is outside the range, draw the render area rectangle----
  if (cardValue < cardMin || cardValue > cardMax) {
    // ----begin drawing big white background rectangle outline----
    ctx.strokeStyle = "grey";
    ctx.lineWidth = 2;
    ctx.strokeRect(
      maxTextWidth + intervalNumberSidePadding,
      valueToTwoAxesVerticalPixel(
        verticalIntervalMin,
        verticalIntervalMin,
        verticalIntervalMax,
        canvasHeight
      ) + renderAreaPadding,
      canvasWidth - 2 * maxTextWidth - 2 * intervalNumberSidePadding,
      valueToTwoAxesVerticalPixel(
        verticalIntervalMax,
        verticalIntervalMin,
        verticalIntervalMax,
        canvasHeight
      ) -
        valueToTwoAxesVerticalPixel(
          verticalIntervalMin,
          verticalIntervalMin,
          verticalIntervalMax,
          canvasHeight
        ) -
        2 * renderAreaPadding
    );
    // ----end drawing big white background rectangle outline----
  }
};

export default drawIntervalLines;
