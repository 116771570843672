import { databaseManifestColors } from "./databaseManifestColors";

export function databaseSimulations() {
  return [
    {
      simulationID: 1930,
      name: "3lb Apple",
      lastSaveDate: "March 3, 2019",
      open: true,
      inputContainers: [
        { status: "valid", input: "x=3", rawInput: "x=3", message: "" }
      ],
      scope: { x: 3 },
      cardContainers: [
        {
          content: {
            color: databaseManifestColors.red,
            cards: [
              {
                play: false,
                cardDisabled: false,
                valueEditable: false,
                value: 3,
                min: 0,
                max: 5,
                valueText: "3",
                minText: "0",
                maxText: "5",
                expression: "x",
                step: 0.1,
                stepText: "0.1",
                unit: "g"
              }
            ]
          },
          scope: { x: 3 }
        }
      ]
    },
    {
      simulationID: 1931,
      name: "6ft Rope",
      lastSaveDate: "May 8, 2019",
      open: false,
      inputContainers: [
        { status: "valid", input: "a=5", rawInput: "a=5", message: "" }
      ],
      scope: { x: 3 },
      cardContainers: [
        {
          content: {
            color: databaseManifestColors.red,
            cards: [
              {
                play: false,
                cardDisabled: false,
                valueEditable: false,
                value: 6,
                min: 0,
                max: 8,
                valueText: "6",
                minText: "0",
                maxText: "8",
                expression: "a",
                step: 0.1,
                stepText: "0.1",
                unit: "multi"
              }
            ]
          },
          scope: { a: 6 }
        }
      ]
    },
    {
      simulationID: 1932,
      name: "Penguins Over Time",
      lastSaveDate: "June 21, 2019",
      open: true,
      inputContainers: [
        {
          status: "valid",
          input: "600(0.8)^t",
          rawInput: "600(0.8)^t",
          message: ""
        }
      ],
      scope: { t: 0 },
      cardContainers: [
        {
          content: {
            color: databaseManifestColors.red,
            cards: [
              {
                play: false,
                cardDisabled: false,
                valueEditable: true,
                value: 0,
                min: 0,
                max: 12,
                valueText: "0",
                minText: "0",
                maxText: "12",
                expression: "t",
                step: 0.1,
                stepText: "0.1",
                unit: "s"
              },
              {
                play: false,
                cardDisabled: false,
                valueEditable: false,
                value: 600,
                min: 0,
                max: 600,
                valueText: "600",
                minText: "0",
                maxText: "600",
                expression: "600(0.8)^t",
                step: 0.1,
                stepText: "0.1",
                unit: "obj"
              }
            ]
          },
          scope: { t: 0 }
        }
      ]
    }
  ];
}
