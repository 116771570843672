import { getNodes } from "../getNodes";
import { autoSetRangeForOneCard } from "../CardStatAutoSettingAlgorithms/autoSetRangeForOneCard";

export function singleVariable(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope,
  specialSymbols
) {
  let varNodes = getNodes(expr).symbolNodes.filter(
    node =>
      specialSymbols.allowableVariables.includes(node.name) ||
      /^[a-zA-Z]_[a-zA-Z0-9]$/g.test(node.name) ||
      /^Delta[a-zA-Z]$/g.test(node.name) ||
      /^Delta[a-zA-Z]_[a-zA-Z0-9]$/g.test(node.name)
  );

  if (
    getNodes(expr).accessorNodes.length === 0 &&
    getNodes(expr).arrayNodes.length === 0 &&
    getNodes(expr).assignmentNodes.length === 0 &&
    getNodes(expr).blockNodes.length === 0 &&
    getNodes(expr).conditionalNodes.length === 0 &&
    getNodes(expr).constantNodes.length === 0 &&
    getNodes(expr).symbolNodes.length === 1 &&
    getNodes(expr).symbolNodes.length === varNodes.length &&
    getNodes(expr).symbolNodes.every(node => {
      return node.name !== "e" && node.name !== "i";
    }) &&
    getNodes(expr).functionNodes.length === 0 &&
    getNodes(expr).functionalAssignmentNodes.length === 0 &&
    getNodes(expr).indexNodes.length === 0 &&
    getNodes(expr).objectNodes.length === 0 &&
    getNodes(expr).operatorNodes.length === 0 &&
    getNodes(expr).parenthesisNodes.length === 0 &&
    getNodes(expr).rangeNodes.length === 0 &&
    getNodes(expr).relationalNodes.length === 0
  ) {
    let scopeVar = getNodes(expr).symbolNodes[0].name;
    let cardScope = {};
    cardScope[scopeVar] = scope[scopeVar] ? scope[scopeVar] : 8;
    let cardMin;
    let cardMax;
    try {
      cardMin = autoSetRangeForOneCard(cardScope[scopeVar]).min;
      cardMax = autoSetRangeForOneCard(cardScope[scopeVar]).max;
    } catch (error) {
      return false;
    }

    if (cardContainers[inputContainerIndex]) {
      // alter existing cards
      return {
        content: {
          color: inputContainerColor,
          cards: [
            {
              play: false,
              cardDisabled: false,
              valueEditable: true,
              value: cardScope[scopeVar],
              min: cardMin,
              max: cardMax,
              minText: cardMin.toString(),
              maxText: cardMax.toString(),
              expression: expr,
              step: 0.1,
              stepText: "0.1",
              unit: cardContainers[inputContainerIndex].content.cards[0].unit
            }
          ]
        },
        scope: cardScope
      };
    } else return false;
  }
}
