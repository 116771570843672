import * as math from "mathjs";
import { getNodes } from "../getNodes";
import { autoSetRangeForOneCard } from "../CardStatAutoSettingAlgorithms/autoSetRangeForOneCard";

math.config({
  number: "BigNumber",
  precision: 64
});

export function numericalExpression(
  expr,
  inputContainerIndex,
  inputContainerColor,
  cardContainers,
  scope,
  specialSymbols
) {
  const nodes = getNodes(expr);

  if (
    nodes.accessorNodes.length === 0 &&
    nodes.arrayNodes.length === 0 &&
    nodes.assignmentNodes.length === 0 &&
    nodes.blockNodes.length === 0 &&
    nodes.conditionalNodes.length === 0 &&
    nodes.symbolNodes.length === nodes.functionNodes.length &&
    (nodes.constantNodes.length >= 1 || nodes.symbolNodes.length >= 1) &&
    nodes.symbolNodes.every(node => {
      return (
        specialSymbols.realConstants.includes(node.name) ||
        specialSymbols.singleValueNumericalOutputFunctionTypes.includes(
          node.name
        )
      );
    }) &&
    nodes.functionalAssignmentNodes.length === 0 &&
    nodes.indexNodes.length === 0 &&
    nodes.objectNodes.length === 0 &&
    nodes.operatorNodes.length >= 0 &&
    nodes.parenthesisNodes.length >= 0 &&
    nodes.rangeNodes.length === 0 &&
    nodes.relationalNodes.length === 0
  ) {
    let cardMin;
    let cardMax;
    try {
      math.eval(expr);
      cardMin = autoSetRangeForOneCard(math.eval(expr)).min;
      cardMax = autoSetRangeForOneCard(math.eval(expr)).max;
    } catch (error) {
      return false;
    }
    if (cardContainers[inputContainerIndex] && math.eval(expr)) {
      // alter existing cards
      return {
        content: {
          color: inputContainerColor,
          cards: [
            {
              play: false,
              cardDisabled: false,
              valueEditable: false,
              value: math.eval(expr) ? math.eval(expr).toString() : "",
              min: cardMin,
              max: cardMax,
              minText: cardMin.toString(),
              maxText: cardMax.toString(),
              expression: expr,
              step: 0.1,
              stepText: "0.1",
              unit: cardContainers[inputContainerIndex].content.cards[0].unit
            }
          ]
        },
        scope: {}
      };
    } else return false;
  }
}
