import valueToVerticalPixelMass from "./valueToVerticalPixelMass";

const drawIntervalLines = (
  ctx,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step
) => {
  ctx.lineWidth = 1;
  ctx.strokeStyle = "grey";
  for (let i = -2; i < 20; i++) {
    ctx.lineWidth = firstLinePosition + step * i === 0 ? 2 : 1;
    ctx.strokeStyle = firstLinePosition + step * i === 0 ? "black" : "grey";
    ctx.beginPath();
    ctx.moveTo(
      0,
      canvasHeight -
        valueToVerticalPixelMass(
          firstLinePosition + step * i,
          cardMin,
          cardMax,
          canvasHeight
        )
    );
    ctx.lineTo(
      canvasWidth,
      canvasHeight -
        valueToVerticalPixelMass(
          firstLinePosition + step * i,
          cardMin,
          cardMax,
          canvasHeight
        )
    );
    ctx.closePath();
    ctx.stroke();
  }
};

export default drawIntervalLines;
