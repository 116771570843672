import React, { useRef, useEffect } from "react";
import drawCard from "./drawCard";

const CardCanvas = ({
  cardValue,
  cardMin,
  cardMax,
  cardUnit,
  handleResize,
  color
}) => {
  // create a reference to the canvas object (see <canvas ref="canvasRef"> in the return method below).
  const canvasRef = useRef(null);

  // resize canvas when the device window size is changed
  window.onresize = () => {
    handleResize();
  };

  // do the stuff in useEffect anytime the component re-renders, such as when the props to the component change
  useEffect(() => {
    // Get the reference to the canvas object.  Then get the canvas' container node.
    // Then set the width of the canvas to that of its container node and height of the canvas to 250/500.
    // Making the pixel width twice the styled width doubles the resolution of the canvas.
    const canvas = canvasRef.current;
    let canvasContainer = canvas.parentNode;
    let canvasContainerWidth = canvasContainer.clientWidth * 2;
    canvas.width = canvasContainerWidth;
    canvas.style.width = `calc(100%)`;
    canvas.height = "400";
    canvas.style.height = "200px";

    // Get the drawing canvas context object (the object that describes the pixels on the canvas).
    const ctx = canvas.getContext("2d");

    // clear the canvas between renders
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // draw the simulation
    drawCard(
      ctx,
      cardValue,
      cardMin,
      cardMax,
      cardUnit,
      canvas.width,
      canvas.height,
      color
    );
  });

  return (
    <div>
      <canvas
        style={{
          borderStyle: "solid",
          borderColor: "black",
          borderRadius: "10px",
          borderWidth: "1px",
          marginBottom: "10px",
          boxShadow: "0px 0px 5px grey"
        }}
        ref={canvasRef}
      />
    </div>
  );
};

export default CardCanvas;
