import * as math from "mathjs";

export function getNodes(expr) {
  const node = math.parse(expr);
  let accessorNodes = [];
  let arrayNodes = [];
  let assignmentNodes = [];
  let blockNodes = [];
  let conditionalNodes = [];
  let constantNodes = [];
  let functionalAssignmentNodes = [];
  let functionNodes = [];
  let indexNodes = [];
  let objectNodes = [];
  let operatorNodes = [];
  let parenthesisNodes = [];
  let rangeNodes = [];
  let relationalNodes = [];
  let symbolNodes = [];
  node.traverse(function(node, path, parent) {
    switch (node.type) {
      case "AccessorNode":
        accessorNodes.push(node);
        //console.log(node.type);
        break;
      case "ArrayNode":
        arrayNodes.push(node);
        //console.log(node.type);
        break;
      case "AssignmentNode":
        assignmentNodes.push(node);
        //console.log(node.type);
        break;
      case "BlockNode":
        blockNodes.push(node);
        //console.log(node.type);
        break;
      case "ConditionalNode":
        conditionalNodes.push(node);
        //console.log(node.type);
        break;
      case "ConstantNode":
        constantNodes.push(node);
        //console.log(node.type);
        break;
      case "FunctionalAssignmentNode":
        functionalAssignmentNodes.push(node);
        //console.log(node.type);
        break;
      case "FunctionNode":
        functionNodes.push(node);
        //console.log(node.type);
        break;
      case "IndexNode":
        indexNodes.push(node);
        //console.log(node.type);
        break;
      case "ObjectNode":
        objectNodes.push(node);
        //console.log(node.type);
        break;
      case "OperatorNode":
        operatorNodes.push(node);
        //console.log(node.type);
        break;
      case "ParenthesisNode":
        parenthesisNodes.push(node);
        //console.log(node.type);
        break;
      case "RangeNode":
        rangeNodes.push(node);
        //console.log(node.type);
        break;
      case "RelationalNode":
        relationalNodes.push(node);
        console.log(node.type);
        break;
      case "SymbolNode":
        symbolNodes.push(node);
        //console.log(node.type);
        break;
      default:
      //console.log(node.type);
    }
  });

  const nodes = {
    accessorNodes: accessorNodes,
    arrayNodes: arrayNodes,
    assignmentNodes: assignmentNodes,
    blockNodes: blockNodes,
    conditionalNodes: conditionalNodes,
    constantNodes: constantNodes,
    functionalAssignmentNodes: functionalAssignmentNodes,
    functionNodes: functionNodes,
    indexNodes: indexNodes,
    objectNodes: objectNodes,
    operatorNodes: operatorNodes,
    parenthesisNodes: parenthesisNodes,
    rangeNodes: rangeNodes,
    relationalNodes: relationalNodes,
    symbolNodes: symbolNodes
  };

  //console.log(nodes.constantNodes);
  //console.log(math.parse(expr));
  //console.log(math.eval(expr));

  return nodes;
}
