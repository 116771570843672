import React, { useState, useEffect } from "react";
import axios from "axios";
import Drawer from "@material-ui/core/Drawer";
import AdminDrawerHeader from "./adminDrawerHeader";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import { databaseExampleSimulations } from "../dataBase/databaseExampleSimulations";
import { getUserSimulations } from "../dataBase/getUserSimulations";
import Link from "react-router-dom";

const drawerWidth = 360;

const exampleSimulations = databaseExampleSimulations();

const useStyles = makeStyles({
  root: {
    display: "flex"
  },
  drawerPaper: {
    width: drawerWidth
  },
  newSimulationButton: {
    paddingLeft: "20px",
    margin: "20px",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: "black",
    borderRadius: "10px",
    display: "flex",
    fontFamily: "Montserrat, sans-serif",
    justifyContent: "left",
    fontSize: "15px",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgb(245,245,245)",
      cursor: "pointer"
    }
  },
  menuSectionTitle: {
    display: "flex",
    fontFamily: "Montserrat, sans-serif",
    justifyContent: "left",
    fontSize: "13px",
    alignItems: "center",
    color: "white",
    height: "30px",
    padding: "10px"
  },
  simulationListItemName: {
    fontFamily: "Montserrat, sans-serif",
    "&:hover": {
      backgroundColor: "rgb(245,245,245)",
      cursor: "pointer"
    }
  }
});

const AdminDrawer = ({
  adminOpen,
  toggleAdminDrawer,
  manifestColors,
  currentUser
}) => {
  const classes = useStyles();

  const [exampleSimulations, setExampleSimulations] = useState([]);

  const userSimulations = getUserSimulations(currentUser.userName);

  /*
  useEffect(() => {
    async function fetchData() {
      const { data: examples } = await axios.get("./examples");
      setExampleSimulations(examples);
      console.log("EXAMPLES!", examples);
    }
    fetchData();
  });
  */

  return (
    <Drawer
      anchor="right"
      open={adminOpen}
      onClose={() => toggleAdminDrawer(false)}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <AdminDrawerHeader toggleAdminDrawer={toggleAdminDrawer} />
      <div>
        <div
          onClick={() => {
            return true;
          }}
          className={classes.newSimulationButton}
        >
          <AddIcon />
          <p style={{ paddingLeft: "15px" }}>New Blank Simulation</p>
        </div>
      </div>
      <div
        className={classes.menuSectionTitle}
        style={{ backgroundColor: manifestColors.red }}
      >
        CURRENT SIMULATION
      </div>
      <Divider />
      <Grid className={classes.simulationListItemName} container>
        <Grid item xs={3} />
        <Grid item xs={9}>
          <p>Mass of Tootsie Rolls</p>
          <p style={{ marginTop: "-15px", fontSize: "10px", color: "grey" }}>
            yesterday
          </p>
        </Grid>
      </Grid>
      <Divider />
      <div
        className={classes.menuSectionTitle}
        style={{ backgroundColor: manifestColors.blue }}
      >
        SAVED SIMULATIONS
      </div>
      <Divider />
      {userSimulations.map((simulation, index) => (
        <React.Fragment key={index}>
          <Grid className={classes.simulationListItemName} container>
            <Grid item xs={3} />
            <Grid className={classes.simulationListItemName} item xs={9}>
              <p>{simulation.name}</p>
              <p
                style={{ marginTop: "-15px", fontSize: "10px", color: "grey" }}
              >
                {`on ${simulation.lastSaveDate}`}
              </p>
            </Grid>
          </Grid>
          <Divider />
        </React.Fragment>
      ))}
      <div
        className={classes.menuSectionTitle}
        style={{ backgroundColor: manifestColors.slate }}
      >
        EXAMPLES
      </div>
      <Divider />
      {exampleSimulations.map(simulation => (
        <React.Fragment>
          <Grid className={classes.simulationListItemName} container>
            <Grid item xs={3} />
            <Grid className={classes.simulationListItemName} item xs={9}>
              <p>{simulation.name}</p>
              <p
                style={{ marginTop: "-15px", fontSize: "10px", color: "grey" }}
              >
                example
              </p>
            </Grid>
          </Grid>
          <Divider />
        </React.Fragment>
      ))}
    </Drawer>
  );
};

export default AdminDrawer;
