import valueToVerticalPixel from "../valueToVerticalPixel";
import determineMaxIntervalNumberWidth from "./determineMaxIntervalNumberWidth";

const drawValue = (
  ctx,
  val,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step
) => {
  let textWidthAdjustment =
    determineMaxIntervalNumberWidth(ctx, firstLinePosition, step) - 55;

  ctx.strokeStyle = "black";
  ctx.lineWidth = 4;

  ctx.beginPath();
  ctx.moveTo(
    canvasWidth * 0.85 - textWidthAdjustment,
    valueToVerticalPixel(val, cardMin, cardMax, canvasHeight)
  );
  ctx.lineTo(
    canvasWidth * 0.85 + 50 - textWidthAdjustment,
    valueToVerticalPixel(val, cardMin, cardMax, canvasHeight)
  );
  ctx.moveTo(
    canvasWidth * 0.85 - 100 - textWidthAdjustment,
    valueToVerticalPixel(val, cardMin, cardMax, canvasHeight)
  );
  ctx.lineTo(
    canvasWidth * 0.85 - 100 + 50 - textWidthAdjustment,
    valueToVerticalPixel(val, cardMin, cardMax, canvasHeight)
  );
  ctx.stroke();
  ctx.closePath();

  ctx.strokeStyle = "grey";
  ctx.lineWidth = 1;
  ctx.setLineDash([5, 5]);

  ctx.beginPath();
  ctx.moveTo(
    canvasWidth * 0.85 + 50 - textWidthAdjustment,
    valueToVerticalPixel(0, cardMin, cardMax, canvasHeight)
  );
  ctx.lineTo(
    canvasWidth * 0.85 + 50 - textWidthAdjustment,
    valueToVerticalPixel(val, cardMin, cardMax, canvasHeight)
  );
  ctx.stroke();
  ctx.closePath();
};

export default drawValue;
