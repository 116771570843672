import valueToHorizontalPixel from "../valueToHorizontalPixel";

const drawValue = (ctx, val, cardMin, cardMax, canvasWidth, canvasHeight) => {
  //draw horizontal number line
  ctx.strokeStyle = "grey";
  ctx.lineWidth = 1;

  ctx.beginPath();
  ctx.moveTo(0, canvasHeight / 2);
  ctx.lineTo(canvasWidth, canvasHeight / 2);
  ctx.closePath();
  ctx.stroke();

  //draw dashed light grey line from 0 line to value line
  ctx.strokeStyle = "grey";
  ctx.lineWidth = 1;
  ctx.setLineDash([5]);
  ctx.beginPath();
  ctx.moveTo(
    valueToHorizontalPixel(0, cardMin, cardMax, canvasWidth),
    0.18 * canvasHeight
  );
  ctx.lineTo(
    valueToHorizontalPixel(val, cardMin, cardMax, canvasWidth),
    0.18 * canvasHeight
  );
  ctx.closePath();
  ctx.stroke();
  ctx.strokeStyle = "grey";
  ctx.setLineDash([]);

  //draw point on number line
  ctx.fillStyle = "black";
  const arcRadius = 10;
  ctx.beginPath();
  ctx.arc(
    valueToHorizontalPixel(val, cardMin, cardMax, canvasWidth),
    canvasHeight / 2,
    arcRadius,
    0,
    2 * Math.PI
  );
  ctx.closePath();
  ctx.fill();

  ctx.lineWidth = 1;
  ctx.strokeStyle = "grey";
};

export default drawValue;
