import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dialogTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "30px"
  },
  inputStyles: {
    fontFamily: "Montserrat, sans-serif",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: "4px",
    width: "400px",
    marginLeft: "30px",
    marginRight: "30px",
    marginBottom: "12px",
    paddingLeft: "8px"
  },
  inputStylesFocused: {
    borderColor: props => props.manifestColors.blue,
    borderWidth: 2
  },
  dialogPaperStyles: {
    borderStyle: "solid",
    borderWidth: 6,
    borderColor: props => props.manifestColors.slate
  },
  createAccountButton: {
    fontFamily: "Montserrat, sans-serif",
    marginLeft: "12px",
    minWidth: "150px",
    color: props => props.manifestColors.red,
    borderColor: props => props.manifestColors.red,
    backgroundColor: props => props.manifestColors.red,
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: props => props.manifestColors.raspberry,
      borderColor: props => props.manifestColors.raspberry,
      color: "white"
    }
  },
  cancelButton: {
    fontFamily: "Montserrat, sans-serif",
    color: props => props.manifestColors.slate,
    borderColor: props => props.manifestColors.slate,
    backgroundColor: props => "white",
    color: props => props.manifestColors.slate,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "white"
    }
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center"
  },
  inputLabel: {
    marginLeft: "30px",
    marginRight: "30px",
    fontFamily: "Montserrat, sans-serif",
    marginBottom: "4px",
    marginTop: "0px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "30px",
    marginBottom: "30px"
  }
});

const DialogCreateAccount = ({
  dialogCreateAccountOpen,
  handleCreateAccountClose,
  manifestColors
}) => {
  const classes = useStyles({
    dialogCreateAccountOpen,
    handleCreateAccountClose,
    manifestColors
  });

  return (
    <Dialog
      PaperProps={{
        elevation: 1,
        classes: { root: classes.dialogPaperStyles }
      }}
      open={dialogCreateAccountOpen}
      onClose={handleCreateAccountClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        id="form-dialog-title"
      >
        CREATE ACCOUNT
      </DialogTitle>
      <DialogContent>
        <p className={classes.inputLabel}>Email</p>
        <div className={classes.inputContainer}>
          <InputBase
            classes={{ focused: classes.inputStylesFocused }}
            className={classes.inputStyles}
          >
            Email
          </InputBase>
        </div>
        <p className={classes.inputLabel}>Name or Nickname</p>
        <div className={classes.inputContainer}>
          <InputBase
            classes={{ focused: classes.inputStylesFocused }}
            className={classes.inputStyles}
          >
            Email
          </InputBase>
        </div>
        <p className={classes.inputLabel}>Password</p>
        <div className={classes.inputContainer}>
          <InputBase
            classes={{ focused: classes.inputStylesFocused }}
            className={classes.inputStyles}
          >
            Email
          </InputBase>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.cancelButton}
            onClick={handleCreateAccountClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.createAccountButton}
            onClick={handleCreateAccountClose}
          >
            Create Account
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogCreateAccount;
