import valueToVerticalPixel from "../valueToVerticalPixel";
import chroma from "chroma-js";
import * as math from "mathjs";
import determineMaxIntervalNumberWidth from "./determineMaxIntervalNumberWidth";

const drawLuminousIntensityBox = (
  ctx,
  val,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step
) => {
  let pixel = valueToVerticalPixel(val, cardMin, cardMax, canvasHeight);
  const pixelToLumiousIntensityLightness =
    math.round((-100 / canvasHeight) * pixel + 100) / 100;

  let luminousIntensityColor;

  if (pixel < 0 || pixel > canvasHeight) {
    luminousIntensityColor = "white";
  } else if (pixel >= 0 && pixel <= canvasHeight) {
    luminousIntensityColor = chroma({
      h: 0,
      s: 0,
      l: pixelToLumiousIntensityLightness
    });
  }

  // this variable helps adjust the space needed on the right to fit large interval numbers
  let textWidthAdjustment =
    determineMaxIntervalNumberWidth(ctx, firstLinePosition, step) - 55;

  /*
    draw the large white background box behind the main light shade box.  It
    sticks out past the main light shade box on the right a little to block the 
    interval lines a little as a visual buffer between the temp box and the lines.
  */

  ctx.fillStyle = "white";
  ctx.fillRect(
    0,
    0,
    canvasWidth * 0.87 - 100 - textWidthAdjustment,
    canvasHeight
  );

  /*
    draw the thin white background box behind the light gradient band.  It
    sticks out past the band on the right and left a little to block the 
    interval lines a little as a visual buffer between the band and the lines.
  */

  ctx.fillStyle = "white";
  ctx.fillRect(
    canvasWidth * 0.87 - 90 - textWidthAdjustment,
    0,
    90,
    canvasHeight
  );

  //draw the main light shade box

  ctx.fillStyle = luminousIntensityColor;
  ctx.fillRect(
    0,
    0,
    canvasWidth * 0.85 - 100 - textWidthAdjustment,
    canvasHeight
  );

  //draw the light shade band

  var grd = ctx.createLinearGradient(
    canvasWidth * 0.85 - 50 - textWidthAdjustment,
    0,
    canvasWidth * 0.85 - textWidthAdjustment,
    canvasHeight
  );
  grd.addColorStop(0, "white");
  grd.addColorStop(1, "black");
  ctx.fillStyle = grd;
  ctx.fillRect(
    canvasWidth * 0.85 - 50 - textWidthAdjustment,
    0,
    50,
    canvasHeight
  );

  //draw the black, right hand border of the main light shade box

  ctx.lineWidth = 2;
  ctx.strokeStyle = "black";

  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.85 - textWidthAdjustment, 0);
  ctx.lineTo(canvasWidth * 0.85 - textWidthAdjustment, canvasHeight);
  ctx.stroke();
  ctx.closePath();

  //draw the black, right and left hand border of the light shade gradient band

  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.85 - 50 - textWidthAdjustment, 0);
  ctx.lineTo(canvasWidth * 0.85 - 50 - textWidthAdjustment, canvasHeight);
  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.85 - 100 - textWidthAdjustment, 0);
  ctx.lineTo(canvasWidth * 0.85 - 100 - textWidthAdjustment, canvasHeight);
  ctx.stroke();
  ctx.closePath();
};

export default drawLuminousIntensityBox;
