import * as math from "mathjs";
math.config({
  number: "BigNumber",
  precision: 64
});

const determineStep = minMaxRange => {
  const roughInterval = math.bignumber(math.divide(minMaxRange, 10));
  const roughPowerOfTen = math.bignumber(math.log10(roughInterval));
  const roundedPowerOfTen = math.bignumber(math.round(roughPowerOfTen));
  const roughScaleFactor = math.bignumber(
    math.divide(roughInterval, math.bignumber(math.pow(10, roundedPowerOfTen)))
  );
  const roundedScaleFactor = math.bignumber(
    math.multiply(math.round(math.divide(roughScaleFactor, 0.5)), 0.5)
  );
  const step = math.bignumber(
    math.multiply(roundedScaleFactor, math.pow(10, roundedPowerOfTen))
  );
  /*
  const step = Number(
    math.eval(`${roundedScaleFactor} * 10 ^ ${roundedPowerOfTen}`).toString()
  );
  */
  return step;
};

export default determineStep;
