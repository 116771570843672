import * as math from "mathjs";

function determineMaxIntervalNumberWidth(ctx, firstLinePosition, step) {
  let maxTextWidth = 0;
  for (let i = 0; i < 20; i++) {
    if (
      ctx.measureText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString()
      ).width > maxTextWidth
    ) {
      maxTextWidth = ctx.measureText(
        math.eval(`${firstLinePosition} + ${step} * ${i}`).toString()
      ).width;
    }
  }

  return maxTextWidth;
}

export default determineMaxIntervalNumberWidth;
