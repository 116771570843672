import valueToVerticalPixel from "../valueToVerticalPixel";

const drawIntervalLines = (
  ctx,
  cardMin,
  cardMax,
  canvasWidth,
  canvasHeight,
  firstLinePosition,
  step
) => {
  for (let i = 0; i < 20; i++) {
    ctx.lineWidth = firstLinePosition + step * i === 0 ? 2 : 1;
    ctx.strokeStyle = firstLinePosition + step * i === 0 ? "black" : "grey";
    ctx.beginPath();
    ctx.moveTo(
      0,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      )
    );
    ctx.lineTo(
      canvasWidth,
      valueToVerticalPixel(
        firstLinePosition + step * i,
        cardMin,
        cardMax,
        canvasHeight
      )
    );
    ctx.closePath();
    ctx.stroke();
  }
};

export default drawIntervalLines;
