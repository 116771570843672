import * as math from "mathjs";
import { autoSetRangeForOneCard } from "./autoSetRangeForOneCard";

math.config({
  number: "BigNumber",
  precision: 64
});

export function autoSetRangeForMultipleCards(values) {
  //values is an array
  let minCardMin = autoSetRangeForOneCard(values[0]).min;
  let maxCardMax = autoSetRangeForOneCard(values[0]).max;
  values.forEach(value => {
    if (autoSetRangeForOneCard(value).min < minCardMin) {
      minCardMin = autoSetRangeForOneCard(value).min;
    }
    if (autoSetRangeForOneCard(value).max > maxCardMax) {
      maxCardMax = autoSetRangeForOneCard(value).max;
    }
  });

  return { min: minCardMin, max: maxCardMax };
}
