import React, { useEffect, useRef } from "react";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import * as math from "mathjs";

math.config({
  number: "BigNumber",
  precision: 64
});

const useStyles = makeStyles({
  root: {
    display: "flex",
    justify: "center",
    justifyContent: "center",
    alignItems: "center",
    color: props => (props.card.valueEditable ? props.color : "lightgrey"),
    height: "31px"
  }
});

const CardPlayButton = props => {
  const classes = useStyles(props);
  const playIcon =
    props.card.play === false ? <PlayCircleOutline /> : <PauseCircleOutline />;
  const numberOfTickMarks = (props.card.max - props.card.min) / props.card.step;
  const animationStep = props.card.step;
  const delay = 7500 / numberOfTickMarks;

  useInterval(
    () => {
      props.animateValue(
        props.containerIndex,
        props.cardIndex,
        animationStep,
        props.card.min,
        props.card.max
      );
    },
    props.card.play ? delay : null
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  return (
    <Typography
      onClick={() => {
        if (props.card.valueEditable) {
          props.togglePlay(
            props.containerIndex,
            props.cardIndex,
            props.card.min,
            props.card.max,
            props.card.step
          );
        }
      }}
      className={classes.root}
    >
      {playIcon}
    </Typography>
  );
};

export default CardPlayButton;
