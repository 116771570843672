import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  iconButton: {
    borderRightStyle: "solid",
    borderRightColor: "lightgrey",
    borderRightWidth: "1px",
    paddingRight: "8px"
  }
}));

const AdminDrawerHeader = ({ toggleAdminDrawer }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.drawerHeader}>
        <div className={classes.iconButton}>
          <IconButton onClick={() => toggleAdminDrawer(false)}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <span style={{ flexGrow: 1 }} />
      </div>
      <Divider />{" "}
    </React.Fragment>
  );
};

export default AdminDrawerHeader;
