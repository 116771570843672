import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import InputsDrawerHeader from "./inputsDrawerHeader";
import InputContainer from "./inputContainer";

const drawerWidth = 360;

const useStyles = makeStyles({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "white"
  },
  drawerPaper: {
    width: drawerWidth
  }
});

const InputsDrawer = ({
  handleDrawerClose,
  open,
  createCardsFromUserInput,
  manifestColors,
  inputContainers,
  cardContainers,
  appendInputContainer,
  insertInputContainer,
  removeInputContainer
}) => {
  const classes = useStyles();

  function determineInputContainerColor(inputContainerIndex) {
    if (inputContainerIndex % 5 === 0) return manifestColors.red;
    else if (inputContainerIndex % 5 === 1) return manifestColors.blue;
    else if (inputContainerIndex % 5 === 2) return manifestColors.green;
    else if (inputContainerIndex % 5 === 3) return manifestColors.slate;
    else if (inputContainerIndex % 5 === 4) return manifestColors.raspberry;
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        root: classes.drawer,
        paper: classes.drawerPaper
      }}
    >
      <InputsDrawerHeader
        handleDrawerClose={handleDrawerClose}
        appendInputContainer={appendInputContainer}
        inputContainers={inputContainers}
      />
      {inputContainers.map((container, index) => (
        <InputContainer
          key={index}
          inputContainerColor={determineInputContainerColor(index)}
          createCardsFromUserInput={createCardsFromUserInput}
          inputContainerIndex={index}
          cardContainers={cardContainers}
          inputContainers={inputContainers}
          appendInputContainer={appendInputContainer}
          insertInputContainer={insertInputContainer}
          removeInputContainer={removeInputContainer}
        />
      ))}
    </Drawer>
  );
};

export default InputsDrawer;
