import React from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import manifestLogoBlack from "../images/ManifestLogoBlack.png";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DialogSaveSimulation from "./dialogSaveSimulation";
import AccountDisplay from "./accountDisplay";
import axios from "axios";

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white",
    borderBottomColor: "lightgrey",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  saveButton: {
    color: (props) => props.manifestColors.blue,
    borderColor: (props) => props.manifestColors.blue,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "11px",
    "&:hover": {
      backgroundColor: (props) => props.manifestColors.blue,
      color: "white",
    },
  },
  createAccountButton: {
    color: (props) => props.manifestColors.red,
    borderColor: (props) => props.manifestColors.red,
    backgroundColor: (props) => props.manifestColors.red,
    minWidth: "145px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "11px",
    "&:hover": {
      backgroundColor: (props) => props.manifestColors.raspberry,
      borderColor: (props) => props.manifestColors.raspberry,
      color: "white",
    },
  },
  signInButton: {
    color: (props) => props.manifestColors.slate,
    borderColor: (props) => props.manifestColors.slate,
    minWidth: "80px",
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "11px",
    "&:hover": {
      backgroundColor: (props) => props.manifestColors.slate,
      color: "white",
    },
  },
  simulationNameRoot: {
    color: "black",
    fontFamily: "Montserrat, sans-serif",
    marginRight: "10px",
    marginLeft: "20px",
    "&:hover": {
      borderStyle: "solid",
      borderColor: "lightgrey",
      borderRadius: "5px",
      borderWidth: "1px",
    },
  },
  simulationNameInput: {
    paddingRight: "10px",
    paddingLeft: "20px",
    textAlign: "right",
    flexGrow: 1,
  },
  userName: {
    fontFamily: "Montserrat, sans-serif",
  },
  accountDisplayWrapper: {
    paddingRight: "30px",
    paddingLeft: "30px",
    display: "flex",
    height: "30px",
    alignItems: "center",
  },
}));

const TopBar = (props) => {
  const classes = useStyles(props);

  let open = props.open;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleClose() {
    setDialogOpen(false);
  }
  async function handleSave() {
    axios({
      method: "POST",
      url: "http://localhost:3900/api/simulations",
      headers: {
        "x-auth-token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZWJjNDIwODBkNmNkZTM0ZTMyZTQ0MzYiLCJuYW1lIjoiSGFycnkiLCJlbWFpbCI6ImhhcnJ5QGhhcnJ5LmNvbSIsImlhdCI6MTU4OTM5NjA0OX0.VxmmbU8EnxUO8x3E4zDZQLkvhzNqlKql5O3iiWtHMes",
      },
      data: {
        name: "Penguins",
        owner: "5ebc42080d6cde34e32e4436",
        private: false,
        open: true,
        inputContainers: [{ name: "FBO", id: "c" }],
        scope: { x: 9 },
        cardContainers: [{ name: "ABO", id: "d" }],
      },
    }).then(function (response) {
      // handle success
      console.log(response);
    });
    setDialogOpen(false);
  }

  function handleClickOpen() {
    setDialogOpen(true);
  }

  let signedIn = false;

  let userName = "harrytomalley";

  const accountDisplay = signedIn ? (
    <span className={classes.accountDisplayWrapper}>
      <p className={classes.userName}>{userName}</p>
    </span>
  ) : (
    <span className={classes.accountDisplayWrapper}>
      <Button
        variant="outlined"
        className={classes.createAccountButton}
        onClick={handleClickOpen}
      >
        CREATE ACCOUNT
      </Button>
      <p style={{ fontFamily: "Montserrat, sans-serif" }}>or</p>
      <Button
        variant="outlined"
        className={classes.signInButton}
        onClick={handleClickOpen}
      >
        SIGN IN
      </Button>
    </span>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      color="inherit"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={props.handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <ChevronRightIcon />
        </IconButton>
        <img width={120} src={manifestLogoBlack} alt="" />
        <span
          style={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: "40px",
              borderRightStyle: "solid",
              borderRightColor: "lightgrey",
              borderRightWidth: "1px",
            }}
          >
            <InputBase
              classes={{
                root: classes.simulationNameRoot,
                input: classes.simulationNameInput,
              }}
              value="Untitled Simulation"
            />
            <Button
              variant="outlined"
              className={classes.saveButton}
              onClick={handleClickOpen}
            >
              SAVE
            </Button>
          </form>
        </span>
        <DialogSaveSimulation
          dialogOpen={dialogOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          manifestColors={props.manifestColors}
        />
        <AccountDisplay manifestColors={props.manifestColors} />
        {/* {accountDisplay} */}
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => props.toggleAdminDrawer(true)}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
