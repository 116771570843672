import React, { useEffect } from "react";
import clsx from "clsx";
import CardsContainer from "./cardsContainer";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 360;

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: "15px",
    /*
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    */
    marginLeft: -drawerWidth,
    backgroundColor: "white",
    minHeight: "2000px"
  },
  contentShift: {
    /*transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),*/
    marginLeft: 0
  }
}));

const CardsArea = ({
  open,
  cardContainers,
  changeUnits,
  changeValueSlider,
  changeValueInput,
  updateCardValueText,
  updateCardMinText,
  updateCardMaxText,
  updateCardMax,
  updateCardMin,
  updateCardStepText,
  updateCardStep,
  togglePlay,
  handleResize,
  animateValue
}) => {
  const classes = useStyles();

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open
      })}
    >
      <div className={classes.drawerHeader} />
      {cardContainers.map((container, index) => (
        <CardsContainer
          key={index}
          changeUnits={changeUnits}
          changeValueSlider={changeValueSlider}
          changeValueInput={changeValueInput}
          updateCardValueText={updateCardValueText}
          updateCardMinText={updateCardMinText}
          updateCardMaxText={updateCardMaxText}
          updateCardMax={updateCardMax}
          updateCardMin={updateCardMin}
          updateCardStepText={updateCardStepText}
          updateCardStep={updateCardStep}
          togglePlay={togglePlay}
          handleResize={handleResize}
          containerIndex={index}
          container={container}
          color={container.color}
          open={open}
          animateValue={animateValue}
        />
      ))}
    </main>
  );
};

export default CardsArea;
